/** @format */

import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tab, { TabProps } from '@mui/material/Tab';

import ThreeDotsMenu from '../../components/common/3-dots-menu/3-dots-menu';
import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import PatientLogs from '../../components/patient-profile/patinet-logs/PatinetLogs';
import PatientEcg from '../../components/patient-profile/patient-activity/PatientEcg';
import { Assessments } from '../../components/patient-profile/assessments/Assessments';
import { AppointmentsCalendar } from '../../components/appointments/AppointmentsCalendar';
import { AllPatientNotesDialog } from '../../components/patient-profile/patient-notes/AllPatientNotes';
import AlertsAndPatientActivity from '../../components/patient-profile/patient-activity/AlertsAndPatientActivity';
import PatientPrescriptionSection from '../../components/patient-profile/prescriptions/PatientPrescriptionSection';
import ProfileSettingPanelSidebar from '../../components/patient-profile/profile-setting-panel/ProfileSettingPanel';

import { PatientProfileContext } from './PatientProfileContext';

import { PrescriptionGeneratorTabsType } from '../../types/PrescriptionsGenerator.types';

import { useLocation, useNavigate } from 'react-router-dom';

interface ActionLayoutProps {
  openSummaryTabDrawer?: boolean;
  setProfileTab?: (value: string) => void;
  profileTab: string;
  handleTabChangeProfileTab: (event: React.SyntheticEvent, newValue: string) => void;

  toggleSymptomsModal: () => void;
  toggleMedicationAdherenceModal: () => void;
  toggleLabReportsModal: () => void;

  prescriptionTabValue: PrescriptionGeneratorTabsType;
  handleTabChangePrescriptionGenerator: (event: React.SyntheticEvent, newValue: PrescriptionGeneratorTabsType) => void;

  notesIsLoading: boolean;

  appointmentFilters: Partial<{ patientSourceId: string; startDate: string; endDate: string; patientDetails: any }>;
  handleChangeAppointmentFilters: (payload: any) => void;
}

const ActionLayout: React.FunctionComponent<ActionLayoutProps> = (props) => {
  const appTheme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAppointmentUpdating, appointmentList, handleSubmitNewPatientAppointment, handleSubmitUpdateAppointment, handleSubmitDeleteAppointment } = React.useContext(PatientProfileContext);

  const _renderTabs = (tab: TabProps, currValue: string) => {
    return (
      <Tab
        key={tab.id}
        id={tab.id}
        label={
          <Typography sx={{ textTransform: 'none' }} variant={currValue === tab.value ? 'fontSemiBold14' : 'fontReg14'} color='#5C6A90'>
            {tab.label}
          </Typography>
        }
        value={tab.value}
      />
    );
  };
  const resetLocationValue = () => {
    navigate(location.pathname, {
      state: { redirectToAlertThreshold: false },
    });
  };
  React.useEffect(() => {
    if (location?.state?.redirectToAlertThreshold && props.setProfileTab) {
      props.setProfileTab('PREFERENCES');
    }
  }, [location]);

  const profileTabs: TabProps[] = [
    {
      id: 'reviews-and-prescriptions',
      value: 'REVIEWS_AND_PRESCRIPTIONS',
      label: 'Reviews & Prescriptions',
    },
    {
      id: 'alerts-and-vitals-graph',
      value: 'ALERTS_AND_VITALS_GRAPH',
      label: 'Alerts & Vitals Graph',
    },
    {
      id: 'ecg',
      value: 'ECG',
      label: 'ECG',
    },

    {
      id: 'appointments',
      value: 'APPOINTMENTS',
      label: 'Appointments',
    },

    {
      id: 'Preferences',
      value: 'PREFERENCES',
      label: 'Preferences',
    },

    {
      id: 'assessments',
      value: 'ASSESSMENTS',
      label: 'Assessments',
    },
    {
      id: 'notes',
      value: 'NOTES',
      label: 'Notes',
    },

    {
      id: 'logs',
      value: 'LOGS',
      label: 'Logs',
    },
  ];

  const threeDotsMenuItems = [
    { id: 1, title: 'Symptoms', value: '', onClick: props.toggleSymptomsModal },
    { id: 2, title: 'Medical Adherence', value: '', onClick: props.toggleMedicationAdherenceModal },
    { id: 3, title: 'Lab Reports', value: '', onClick: props.toggleLabReportsModal },
  ];

  return (
    <Box>
      <AppBar
        elevation={0}
        position='static'
        sx={{
          padding: 0,
          margin: 0,
          background: appTheme.palette.customColor.tabBgColor,
          borderBottom: '.6px solid #d5d5d6',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        }}>
        <Tabs
          TabIndicatorProps={{
            sx: {
              height: '3px',
            },
          }}
          variant='scrollable'
          scrollButtons='auto'
          value={props.profileTab}
          onChange={props.handleTabChangeProfileTab}
          className='patient-profile-tabssssss'
          sx={{
            '> .MuiButtonBase-root': { color: '#3E4685 !important' },
            '> .MuiTabs-scrollButtons': { display: 'inherit !important' },
            backgroundColor: 'white',
            '& > div > div div:last-child': { alignSelf: 'center', marginLeft: 'auto' },
          }}
          aria-label='full width tabs'>
          {profileTabs.map((e) => _renderTabs(e, props.profileTab))}

          <ThreeDotsMenu menuItems={threeDotsMenuItems} />
        </Tabs>
      </AppBar>
      <Grid sx={{ paddingTop: '10px' }}>{props.profileTab === 'ALERTS_AND_VITALS_GRAPH' && <AlertsAndPatientActivity />}</Grid>
      <Grid>{props.profileTab === 'ECG' && <PatientEcg />}</Grid>
      <Grid sx={{ height: '70vh' }}>
        <Scrollbars autoHide>
          {props.profileTab === 'REVIEWS_AND_PRESCRIPTIONS' && (
            <PatientPrescriptionSection prescriptionTabValue={props.prescriptionTabValue} handleTabChangePrescriptionGenerator={props.handleTabChangePrescriptionGenerator} />
          )}

          {props.profileTab === 'ASSESSMENTS' && <Assessments />}

          {/* {profileTab === 'VITALS_GRAPH' && <AlertsAndPatientActivity />} */}

          {props.profileTab === 'NOTES' && <Box>{props.notesIsLoading ? <CustomLoading /> : <AllPatientNotesDialog disabled={false} />}</Box>}

          {props.profileTab === 'PREFERENCES' && (
            <ProfileSettingPanelSidebar
              openDrawer
              toggleSettingPanel={console.info}
              showPatientAlertsRulesTab
              showPatientAlertsThresholdTab
              showAlertThresholds={location?.state?.redirectToAlertThreshold}
              resetLocationValue={resetLocationValue}
            />
          )}

          {props.profileTab === 'APPOINTMENTS' && (
            <Box sx={{ paddingRight: '10px' }}>
              <AppointmentsCalendar
                appointmentList={appointmentList}
                isAppointmentUpdating={isAppointmentUpdating}
                handleSubmitNewPatientAppointment={handleSubmitNewPatientAppointment}
                handleSubmitUpdateAppointment={handleSubmitUpdateAppointment}
                handleSubmitDeleteAppointment={handleSubmitDeleteAppointment}
                appointmentFilters={props.appointmentFilters}
                handleChangeAppointmentFilters={props.handleChangeAppointmentFilters}
                hidePatientSearchFilter={true}
              />
            </Box>
          )}

          {props.profileTab === 'LOGS' && <PatientLogs />}
        </Scrollbars>
      </Grid>
    </Box>
  );
};

export default ActionLayout;
