/** @format */

import React, { useContext, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Button, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { vitalsUnitConverters } from 'vitals-convertor';
import { mapObject, isNaN } from 'underscore';
import { isEmpty, get, isEqual } from 'lodash';

// APIs.
import { fetchPatientInfo } from '../../service/patient-info';
import { approvePatient, enrollNewPatient, updateEnrolledPatient } from '../../service/patient-list';

// Components.
import { PatientEnrolment } from './PatientEnrolment';

// Constants.
import {
  SYMPTOMS_KEYS_MAP,
  PATIENT_DRAFT_STATUS,
  PATIENT_APPROVAL_STATUS,
  CARDIOVASULCAR_DISEASES_KEY_MAP,
  EDIT_BASIC_DETAILS_INITIAL_STATE,
  NON_CARDIOVASULCAR_DISEASES_KEY_MAP,
} from '../../constants/PatientEnrollment';
import { HEADER_OFFSET } from '../../constants/tabs';
import { VITALS_MAP } from '../../constants/PatientVitals';
import { initialState as CONFIG_INITIAL_STATE, ConfigStateInterface } from '../../reducers/config-reducer/ConfigSlice';

// Contexts & Reducers.
import { clearToast, setToast } from '../../reducers/toast-reducer/ToastReducer';

// Types.
import { EditDetailsType } from '../../types/EditDetails.types';
import { DropdownOptionType } from '../../types/CustomForm.types';
import { OrganizationMedicationType } from '../../types/SystemMedications.types';

// Functions and Validators.
import { patientSaveSchema } from '../../validations/enrolment-validation.joi';
import { awaitForSometime, bmiCalculator, convertScrtoEgfr, formatFormData } from '../../utils/functions/enrolment-form';
import { PatientDevice } from '../../types/Patients.types';
import { deletePatientDeviceInfo, fetchAllMappedDevices, fetchUnmappedDevices, updatePatientDeviceInfo } from '../../service/devices';
import { updateDraftPatientInfo } from '../../reducers/patient-list-reducer/DraftPatientListSlice';
import { PatientListNewType } from '../../types/PatientListPage.types';
import { UserSessionContext } from '../../context/UserSession';

export interface FormDataInterface {
  physician: { assignPhysician?: string };
  patientBasic: {
    enrollmentDate: string;
    patientId?: string;
    firstName?: string;
    lastName?: string;
    createdAt?: string;
    ethnicity?: string;
    cardiologistId?: string;
    age?: string;
    gender?: string;
    phoneNumber?: string;
    email?: string;
    address?: string;
    phoneNumberAlt?: string;
    relationshipAltContact?: string;
    relationshipAltContactName?: string;
  };
  medicalHistory: {
    dateOfHFDiagnosis?: string;
    lvef?: string;
    hfType: string;
    nyhaClass?: string;
    lastHospitalizationDate?: string;

    diseasesAndSymptoms?: {
      cardiovascularDiseases: {
        hypertension?: boolean;
        coronaryHeartDisease?: boolean;
        priorMyocardialInfarction?: boolean;
        valvularHeartDisease?: boolean;
        atrialFibrillation?: boolean;
        stroke?: boolean;
        aorticPlaque?: boolean;
        peripheralArteryDisease?: boolean;
        typeIDiabetesMellitus?: boolean;
        typeIIDiabetesMellitus?: boolean;
        chronicKidneyDisease?: boolean;
        others?: boolean;
        otherCardiovascularDiseases?: boolean;
      };
      nonCardiovascularDiseases: {
        copd?: boolean;
        hypothyroidism?: boolean;
        anaemia: boolean;
        ironDeficiency?: boolean;
        obesity?: boolean;
        smoking?: boolean;
        alcohol?: boolean;
        dementia?: boolean;
        others?: boolean;
        otherNonCardiovascularDiseases?: boolean;
      };
      symptoms: {
        noSymptoms?: boolean;
        fatigue?: boolean;
        shortnessOfBreath?: boolean;
        shortness?: boolean;
        dyspnea?: boolean;
        dyspneaBreathlesson?: boolean;
        orthopnea?: boolean;
        orthopneaBreathless?: boolean;
        paraoxysmalNocturnalDyspnea?: boolean;
        paraoxysmal?: boolean;
        wheezing?: boolean;
        dizziness?: boolean;
        swellingHandsFeet?: boolean;
        swellingHandsLegsFeet?: boolean;
        swellingLipsEyes?: boolean;
        dryCough?: boolean;
        chestTightness?: boolean;
        palpitations?: boolean;
        palpitation?: boolean;
        others?: boolean;
        otherSymptoms?: boolean;
      };
    };

    allergies?: string;
    vitalsReference?: {
      vitalsReferenceDate: Date;
      vitals: {
        bp: number;
        hr: number;
        weight: number;
        height: { feet: number; inches: number };
        bmi: number;
        chloride?: number;
        bnp?: number;
        ntprobnp?: number;
        scr?: number;
        egfr?: number;
        potassium?: number;
        calcium?: number;
        sodium?: number;
        glucose?: number;
        bloodUreaNitrogen?: number;
        hemoglobin?: number;
        hematocrit?: number;
        hba1c?: number;
        bicarbonate?: number;
      };
    };
  };

  previousMedications: any;
}

const FORM_DATA_INITIAL_STATE: FormDataInterface = {
  patientBasic: {
    enrollmentDate: DateTime.now().toFormat('yyyy-MM-dd'),
  },
  physician: { assignPhysician: '' },
  medicalHistory: {
    hfType: 'HFrEF',
  },
  previousMedications: [],
};

export interface PatientEnrolmentContextType {
  organizatoinFields: ConfigStateInterface;

  tabPhysicianRef: React.MutableRefObject<HTMLDivElement | null>;
  tabPatientBasicDetailsRef: React.MutableRefObject<HTMLDivElement | null>;
  tabTCPRef: React.MutableRefObject<HTMLDivElement | null>;
  tabPatientRulesRef: React.MutableRefObject<HTMLDivElement | null>;
  tabPatientMedicalHistoryRef: React.MutableRefObject<HTMLDivElement | null>;
  buttonRef: React.MutableRefObject<any>;

  handleChangeTabs: (newValue: string) => void;

  editDetails: EditDetailsType;
  handleClickEditDetails: (payload: string) => void;

  allDoctors: DropdownOptionType[];
  loading: boolean;
  // fetchInfoIsLoading: boolean;
  symptomsIsLoading: boolean;

  formData: FormDataInterface;
  formDataRef: React.MutableRefObject<any>;
  prevMedicationRef: React.MutableRefObject<any>;
  onChangeForm: (data: FormDataInterface, id: string) => void;

  pathname: string;

  customValidate: (formData: any, errors: any) => any;
  transformErrors: (erros: any[]) => any;
  customElementScollToView: (id: string) => void;
  onSubmit: (event: any, data: any) => void;
  isApiCalling: boolean;

  showApproveButton: boolean;

  isEditTCPMedications: boolean;
  setIsEditTCPMedications: React.Dispatch<React.SetStateAction<boolean>>;

  organizationMedications: OrganizationMedicationType[];

  showCancelChangesDialog: boolean;
  onClickCancelSavedChanges: () => void;
  onClickHideCancelChangesConfirmationDialog: () => void;
  onClickShowCancelChangesConfirmationDialog: () => void;

  submitApproveIsLoading: boolean;
  handleClickSubmitApprove: () => void;

  unmappedDevices: PatientDevice[];
  mappedDevices: PatientDevice[];
  isDeviceInfoUpdating: boolean;
  openSettingDrawer: boolean;
  handleCloseSettingDrawer: () => void;
  handleClickOpenSettingDrawer: () => void;
  handleSubmitPatientDeviceInfo: (value: string) => void;
  handleSubmitDeletePatientDeviceInfo: (deviceInfo: PatientDevice) => void;
}

const PATIENT_ENROLMENT_INITIAL_STATE: PatientEnrolmentContextType = {
  organizatoinFields: CONFIG_INITIAL_STATE,

  tabPhysicianRef: null as unknown as React.MutableRefObject<HTMLDivElement | null>,
  tabPatientBasicDetailsRef: null as unknown as React.MutableRefObject<HTMLDivElement | null>,
  tabTCPRef: null as unknown as React.MutableRefObject<HTMLDivElement | null>,
  tabPatientRulesRef: null as unknown as React.MutableRefObject<HTMLDivElement | null>,
  tabPatientMedicalHistoryRef: null as unknown as React.MutableRefObject<HTMLDivElement | null>,
  buttonRef: null as unknown as React.MutableRefObject<any>,

  handleChangeTabs: console.info,

  editDetails: EDIT_BASIC_DETAILS_INITIAL_STATE,
  handleClickEditDetails: console.info,

  allDoctors: [],
  loading: false,
  symptomsIsLoading: false,

  formData: FORM_DATA_INITIAL_STATE,
  formDataRef: null as unknown as React.MutableRefObject<FormDataInterface | null>,
  prevMedicationRef: null as unknown as React.MutableRefObject<FormDataInterface | null>,
  onChangeForm: console.info,

  pathname: '',

  customValidate: console.info,
  transformErrors: console.info,
  customElementScollToView: console.info,
  onSubmit: console.info,
  isApiCalling: false,

  showApproveButton: false,

  isEditTCPMedications: false,
  setIsEditTCPMedications: console.info,

  organizationMedications: [],

  showCancelChangesDialog: false,
  onClickCancelSavedChanges: console.info,
  onClickHideCancelChangesConfirmationDialog: console.info,
  onClickShowCancelChangesConfirmationDialog: console.info,

  submitApproveIsLoading: false,
  handleClickSubmitApprove: console.info,

  unmappedDevices: [],
  mappedDevices: [],
  isDeviceInfoUpdating: false,
  openSettingDrawer: false,
  handleCloseSettingDrawer: console.info,
  handleClickOpenSettingDrawer: console.info,
  handleSubmitPatientDeviceInfo: console.info,
  handleSubmitDeletePatientDeviceInfo: console.info,
};

export const PatientEnrolmentContext = React.createContext(PATIENT_ENROLMENT_INITIAL_STATE);

const PatientEnrolmentContextProvider: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const organizatoinFields = useAppSelector((state) => state.config) as ConfigStateInterface;
  const organizationMedications = useAppSelector((state) => state.orgMedications);
  const symptoms = useAppSelector((state) => state.patientSymptoms);
  const reduxDispatch = useAppDispatch();

  const tabPhysicianRef = React.useRef<HTMLDivElement>(null);
  const tabPatientBasicDetailsRef = React.useRef<HTMLDivElement>(null);
  const tabPatientMedicalHistoryRef = React.useRef<HTMLDivElement>(null);
  const tabTCPRef = React.useRef<HTMLDivElement>(null);
  const tabPatientRulesRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<any>(null);

  const formDataRef = React.useRef<any>(null);
  const prevMedicationRef = React.useRef<any>(null);

  const [editDetails, setEditDetails] = React.useState<EditDetailsType>(EDIT_BASIC_DETAILS_INITIAL_STATE);
  const [formData, setFormData] = React.useState<FormDataInterface>(FORM_DATA_INITIAL_STATE);
  const [originalFormData, setOriginalFormData] = React.useState<FormDataInterface>(FORM_DATA_INITIAL_STATE);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [isApiCalling, setIsApiCalling] = React.useState<boolean>(false);
  const [showApproveButton, setShowApproveButton] = React.useState<boolean>(false);
  const [isEditTCPMedications, setIsEditTCPMedications] = React.useState<boolean>(false);
  const [showCancelChangesDialog, setShowCancelChangesDialog] = React.useState<boolean>(false);
  const [submitApproveIsLoading, setSubmitApproveIsLoading] = React.useState<boolean>(false);
  const [openSettingDrawer, setOpenSettingDrawer] = React.useState(false);
  const [unmappedDevices, setUnMappedDevices] = React.useState<PatientDevice[]>([]);
  const [mappedDevices, setAllMappedDevices] = React.useState<PatientDevice[]>([]);
  const [isDeviceInfoUpdating, setIsDeviceInfoUpdating] = React.useState<boolean>(false);

  const doctors = useAppSelector((state) => state.userRegion.orgUsers);

  const toastState = useAppSelector((state) => state.toast);

  const currentUser = useContext(UserSessionContext);

  useEffect(() => {
    const currentDoctor = doctors.find((e) => e.value === currentUser.userSession.userId)?.value || '';

    setFormData({
      ...formData,
      physician: {
        assignPhysician: currentDoctor,
      },
    });
  }, []);

  useEffect(() => {
    if (toastState) {
      toastState.forEach((item) => {
        if (item.code === 'success' && ['Successfully submitted for approval!', 'Successfully saved draft!'].includes(item.message)) {
          reduxDispatch(clearToast(item));
        }
      });
    }
  }, []);

  const getUnmappedDevices = async () => {
    try {
      const response = await fetchUnmappedDevices();
      setUnMappedDevices(JSON.parse(JSON.stringify(response)));
    } catch (error) {
      reduxDispatch(
        setToast({
          code: 'error',
          message: 'Failed to fetch unmapped devices',
          isOpen: true,
        }),
      );
    }
  };

  const getAllMappedDevices = async () => {
    try {
      const response = await fetchAllMappedDevices();
      setAllMappedDevices(JSON.parse(JSON.stringify(response)));
    } catch (error) {
      reduxDispatch(
        setToast({
          code: 'error',
          message: 'Failed to fetch mapped devices',
          isOpen: true,
        }),
      );
    }
  };

  /**
   * Fetch patient info.
   * - API call.
   * - convert data to required format.
   */
  const fetchAllPatientInfo = async (): Promise<FormDataInterface | undefined> => {
    if (!params.patientSourceId) {
      return;
    }

    try {
      const response = await fetchPatientInfo(params.patientSourceId, { isEnrolmentDetails: true });
      let otherSymptoms = '';
      const symptoms = response.medicalHistory.symptoms.reduce((acc: any, curr: any) => {
        if (curr.shortcode === 'OTHERS' && curr.description !== 'Free Text') {
          otherSymptoms = curr.description;
        }
        return {
          ...acc,
          [SYMPTOMS_KEYS_MAP[curr.shortcode]]: true,
        };
      }, {});

      let otherNonCardiovascularDiseases = '';
      const nonCardiovascularDiseases = response?.medicalHistory?.nonCardiovascularDiseases.reduce((acc: any, curr: any) => {
        if (NON_CARDIOVASULCAR_DISEASES_KEY_MAP[curr] === undefined) {
          otherNonCardiovascularDiseases = curr;
        }
        return {
          ...acc,
          [NON_CARDIOVASULCAR_DISEASES_KEY_MAP[curr]]: true,
        };
      }, {});

      let otherCardiovascularDiseases = '';
      const cardiovascularDiseases = response?.medicalHistory?.cardiovascularDiseases.reduce((acc: any, curr: any) => {
        if (CARDIOVASULCAR_DISEASES_KEY_MAP[curr] === undefined) {
          otherCardiovascularDiseases = curr;
        }
        return {
          ...acc,
          [CARDIOVASULCAR_DISEASES_KEY_MAP[curr]]: true,
        };
      }, {});

      // Format the data.
      const formattedData: FormDataInterface = {
        physician: {
          assignPhysician: response.basicDetails?.cardiologistId ? response.basicDetails?.cardiologistId : undefined,
        },

        patientBasic: {
          address: response.basicDetails?.address ? response.basicDetails?.address : undefined,
          age: response.basicDetails?.age ? response.basicDetails?.age : undefined,
          createdAt: response.basicDetails?.createdAt ? response.basicDetails?.createdAt : undefined,
          email: response.basicDetails?.email ? response.basicDetails?.email : undefined,
          ethnicity: response.basicDetails?.ethnicity ? response.basicDetails?.ethnicity : undefined,
          firstName: response.basicDetails?.firstName ? response.basicDetails?.firstName : undefined,
          gender: response.basicDetails?.gender ? response.basicDetails?.gender : undefined,
          lastName: response.basicDetails?.lastName ? response.basicDetails?.lastName : undefined,
          patientId: response.basicDetails?.patientId ? response.basicDetails?.patientId : undefined,
          cardiologistId: response.basicDetails?.cardiologistId ? response.basicDetails?.cardiologistId : undefined,
          phoneNumber: response.basicDetails?.phoneNumber ? response.basicDetails?.phoneNumber.split('+').join('') : undefined,
          phoneNumberAlt: response.basicDetails?.phoneNumberAlt ? response.basicDetails?.phoneNumberAlt.split('+').join('') : undefined,
          enrollmentDate: response.basicDetails?.enrollmentDate ? response.basicDetails?.enrollmentDate : undefined,
          relationshipAltContact: response.basicDetails?.relationshipAltContact ? response.basicDetails?.relationshipAltContact : undefined,
          relationshipAltContactName: response.basicDetails?.relationshipAltContactName ? response.basicDetails?.relationshipAltContactName : undefined,
        },

        medicalHistory: {
          dateOfHFDiagnosis: response.medicalHistory?.heartFailureMedicalHistory?.dateOfHFDiagnosis,
          lvef: response.medicalHistory?.heartFailureMedicalHistory?.lvef,
          hfType: response.medicalHistory?.heartFailureMedicalHistory?.hfType,
          nyhaClass: response.medicalHistory?.heartFailureMedicalHistory?.nyhaClass,
          lastHospitalizationDate: response.medicalHistory?.heartFailureMedicalHistory?.lastHospitalizationDate,

          diseasesAndSymptoms: {
            symptoms: {
              ...symptoms,
              otherSymptoms,
            },
            nonCardiovascularDiseases: {
              ...nonCardiovascularDiseases,
              others: otherNonCardiovascularDiseases.length !== 0,
              otherNonCardiovascularDiseases,
            },
            cardiovascularDiseases: {
              ...cardiovascularDiseases,
              others: otherCardiovascularDiseases.length !== 0,
              otherCardiovascularDiseases,
            },
          },

          vitalsReference: response?.medicalHistory?.vitalsReference?.reduce((acc: any, curr: any) => {
            if (curr.type === 'BLOOD_PRESSURE') {
              return {
                ...acc,
                vitalsReferenceDate: DateTime.fromJSDate(new Date(curr.timestamp)).toFormat('yyyy-MM-dd HH:mm'),
                vitals: {
                  ...acc.vitals,
                  bp: {
                    sbp: Number(curr.measurements.find((e: any) => e.name === 'SYSTOLIC').value),
                    dbp: Number(curr.measurements.find((e: any) => e.name === 'DIASTOLIC').value),
                  },
                },
              };
            } else if (curr.type === 'HEIGHT') {
              return {
                ...acc,
                vitalsReferenceDate: DateTime.fromJSDate(new Date(curr.timestamp)).toFormat('yyyy-MM-dd HH:mm'),
                vitals: {
                  ...acc.vitals,
                  height: {
                    feet: Number(curr.measurements.find((e: any) => e.name === 'FEET').value),
                    inches: Number(curr.measurements.find((e: any) => e.name === 'INCHES').value),
                  },
                },
              };
            } else if (curr.type === 'WEIGHT') {
              const updatedWeight = vitalsUnitConverters({ type: 'WEIGHT', measurements: [{ value: Number(curr.measurements[0]?.value), type: 'WEIGHT', unit: 'lbs' }] }, 'kgs');

              return {
                ...acc,
                vitalsReferenceDate: DateTime.fromJSDate(new Date(curr.timestamp)).toFormat('yyyy-MM-dd HH:mm'),
                vitals: {
                  ...acc.vitals,
                  [VITALS_MAP[curr.type]]: organizatoinFields.vitalsUnit.weight === 'kgs' ? Number(updatedWeight.measurements[0].value) : Number(curr.measurements[0]?.value),
                },
              };
            } else {
              return {
                ...acc,
                vitalsReferenceDate: DateTime.fromJSDate(new Date(curr.timestamp)).toFormat('yyyy-MM-dd HH:mm'),
                vitals: {
                  ...acc.vitals,
                  [VITALS_MAP[curr.type]]: Number(curr.measurements[0]?.value),
                },
              };
            }
          }, {}),

          allergies: response?.medicalHistory?.allergies || '',
        },

        previousMedications: response?.enrolmentDetails?.previousMedications?.payload?.prescription?.prescriptionMedications || [],
      };
      return formattedData;
    } catch (error) {
      console.log('>>>>errror', error);
      reduxDispatch(
        setToast({
          code: 'error',
          message: 'Failed to fetch patient information',
          isOpen: true,
        }),
      );
    }
  };

  const fetchUserPreset = React.useCallback(async (userId: string, hfType: string, data: FormDataInterface) => {
    // TODO: Preset is need to decided
  }, []);

  const fetchUsersAndPreset = React.useCallback(async () => {
    setLoading(true);

    // const users = await fetchUsers();

    // if (users) {
    //   setAllDoctors(users);
    // }

    const savedFormData = sessionStorage.getItem('form-data');

    if (savedFormData !== null && !isEmpty(JSON.parse(savedFormData))) {
      setFormData(JSON.parse(savedFormData));
      formDataRef.current = JSON.parse(savedFormData);

      setLoading(false);
      return;
    }

    setLoading(false);
  }, []);

  const fetchUsersAndPatientInfoInDraftOrApproveMode = React.useCallback(async () => {
    setLoading(true);
    setEditDetails(mapObject(editDetails, () => true));

    // const users = await fetchUsers();

    // if (users) {
    //   setAllDoctors(users);
    // }

    const patientInfo = await fetchAllPatientInfo();

    if (patientInfo) {
      setFormData(JSON.parse(JSON.stringify(patientInfo)));
      setOriginalFormData(JSON.parse(JSON.stringify(patientInfo)));
      formDataRef.current = JSON.parse(JSON.stringify(patientInfo));
      const { previousMedications } = patientInfo;
      prevMedicationRef.current = JSON.parse(JSON.stringify(previousMedications));
    }

    setLoading(false);
  }, []);

  React.useEffect(() => {
    getUnmappedDevices();
    getAllMappedDevices();

    if (pathname.includes('approve') || pathname.includes('draft')) {
      fetchUsersAndPatientInfoInDraftOrApproveMode();

      if (pathname.includes('approve')) {
        setShowApproveButton(true);
      }
    } else if (pathname.includes('enroll')) {
      fetchUsersAndPreset();
    }
  }, []);

  const handleChangeTabs = (newValue: string) => {
    let elementPosition: number;

    if (newValue === 'PHYSICIAN' && tabPhysicianRef.current) {
      elementPosition = Number(tabPhysicianRef.current?.getBoundingClientRect().top);
    } else if (newValue === 'PERSONAL_DETAIL' && tabPatientBasicDetailsRef.current) {
      elementPosition = Number(tabPatientBasicDetailsRef.current?.getBoundingClientRect().top);
    } else if (newValue === 'MEDICAL_HISTORY' && tabPatientMedicalHistoryRef.current) {
      elementPosition = Number(tabPatientMedicalHistoryRef.current?.getBoundingClientRect().top);
    } else if (newValue === 'CARE_PLAN') {
      elementPosition = Number(tabTCPRef.current?.getBoundingClientRect().top);
    } else if (newValue === 'PATIENT_RULES') {
      elementPosition = Number(tabPatientRulesRef.current?.getBoundingClientRect().top);
    } else {
      elementPosition = Number(tabPhysicianRef.current?.getBoundingClientRect().top);
    }

    const offsetPosition = elementPosition! + window.pageYOffset - HEADER_OFFSET;

    window.scroll({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const handleClickEditDetails = (payload: string) => {
    setEditDetails((prev) => ({ ...prev, [payload]: false }));

    if (pathname.includes('approve')) {
      setShowApproveButton(false);
    }

    if (!isEqual(formDataRef.current, formData)) {
      console.log('updating the data');
      setFormData({ ...formDataRef.current });
    }
  };

  const onClickCancelSavedChanges = () => {
    setFormData(originalFormData);
    formDataRef.current = originalFormData;
    setShowCancelChangesDialog(false);
    setEditDetails(mapObject(editDetails, () => true));

    if (pathname.includes('approve')) {
      setShowApproveButton(true);
    }

    reduxDispatch(setToast({ code: 'success', message: 'Successfully discarded all changes.', isOpen: true, id: Math.random() }));
  };

  const onClickHideCancelChangesConfirmationDialog = () => {
    setShowCancelChangesDialog(false);
  };

  const onClickShowCancelChangesConfirmationDialog = () => {
    setShowCancelChangesDialog(true);
  };

  const customValidate = (formData: FormDataInterface, errors: any) => {
    let schema = patientSaveSchema('EDIT', organizatoinFields, formData?.previousMedications?.length > 0);

    if (buttonRef?.current && buttonRef?.current?.click && buttonRef?.current?.click === 'save-draft') {
      // buttonRef.current.click = '';
      schema = patientSaveSchema('DRAFT', organizatoinFields, formData?.previousMedications?.length > 0);
    }

    if (buttonRef?.current && buttonRef?.current?.click && buttonRef?.current?.click === 'save-approve') {
      // buttonRef.current.click = '';
      schema = patientSaveSchema('APPROVE', organizatoinFields, formData?.previousMedications?.length > 0);
    }

    const errorSchema = schema.validate(formData, { abortEarly: false, allowUnknown: true });

    if (errorSchema && errorSchema.error) {
      let firstErrorPath = '';

      errorSchema.error?.details.forEach((item: any) => {
        if (!firstErrorPath) {
          firstErrorPath = `root_${item.path.join('_')}`;
        }

        if (item.path && item.path?.length > 0 && ['sbp', 'dbp', 'feet', 'inches'].includes(item.path[item.path?.length - 1])) {
          item.path.pop();
        }

        const path = get(errors, [...item.path]);
        if (isEmpty(path?.__errors)) {
          path?.addError(item.message);
        }
      });
    }

    const isPrescitionError = errorSchema.error?.details?.some((item) => item.path[0] === 'previousMedications');

    if (isPrescitionError) {
      errors.previousMedications?.[0]?.addError('Medication is required');
    }

    console.log('organizatoinFieldsorganizatoinFields errors', { formData, errorSchema, schema, errors });
    return errors;
  };

  const transformErrors = (errors: any[]) => {
    return errors.map((error) => {
      const path = error.property.split('.');
      const fieldName = path[path?.length - 1] || '';

      const formatFieldName: string = fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());

      if (error.name === 'type') {
        error.message = `${formatFieldName} cannot be empty`;
      }

      if (error.name === 'pattern') {
        error.message = `Please enter valid ${formatFieldName}`;
      }

      if (error.name === 'minLength') {
        error.message = `${formatFieldName} should be minimum ${error?.params?.limit} char`;
      }

      if (error.name === 'maxLength') {
        error.message = `${formatFieldName} should not exceed ${error?.params?.limit} char`;
      }

      if (error.name === 'maximum') {
        error.message = `${formatFieldName} should be less or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'minimum') {
        error.message = `${formatFieldName} should be  greater or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'required') {
        error.message = `Other ${formatFieldName} is required`;
      }

      if (error.message.includes('patientRules.rules')) {
        error.message = 'Invalid Medication rule.';
      }

      return error;
    });
  };

  const customElementScollToView = (id: string) => {
    try {
      const partialId = document.querySelector(`[id^="${id}"]`)?.id;
      const element = document.getElementById(partialId as string);

      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY - 150;
        window.scroll({
          top: y,
          behavior: 'smooth',
        });
      }
    } catch (error) {
      console.log('ERROR: Patient Enrollment scroll to error field => ', error);
    }
  };

  const calculateBmi = (data: FormDataInterface): void => {
    const height = data.medicalHistory.vitalsReference?.vitals?.height;
    const weight = data.medicalHistory.vitalsReference?.vitals?.weight;

    if (!height) {
      return;
    }

    if (!weight) {
      return;
    }

    const bmi = bmiCalculator(height, weight, organizatoinFields.vitalsUnit.weight);

    const newFormData = {
      ...data,
      medicalHistory: {
        ...data.medicalHistory,
        vitalsReference: {
          ...data.medicalHistory.vitalsReference!,
          vitals: {
            ...data.medicalHistory.vitalsReference?.vitals!,
            bmi: Number(bmi),
            height: data.medicalHistory.vitalsReference?.vitals.height!,
            weight: data.medicalHistory.vitalsReference?.vitals.weight!,
          },
        },
      },
    };

    setFormData(JSON.parse(JSON.stringify(newFormData)));
    formDataRef.current = JSON.parse(JSON.stringify(newFormData));
  };

  const calculateEgfr = (data: FormDataInterface): void => {
    type Values = number | string | undefined;

    type RemoveString<TType> = TType extends string ? never : TType;
    type RemoveNumber<TType> = TType extends number ? never : TType;

    const scr: RemoveString<Values> = data?.medicalHistory?.vitalsReference?.vitals?.scr;
    let egfr = data?.medicalHistory?.vitalsReference?.vitals?.egfr;
    const age: RemoveString<Values> = Number(data?.patientBasic?.age);
    const gender = data?.patientBasic?.gender;
    const race: RemoveNumber<Values> = data?.patientBasic?.ethnicity;

    if (gender && age && scr && race && !isNaN(age) && !isNaN(scr) && !isEmpty(gender)) {
      const tempEGFR = Math.round(
        convertScrtoEgfr({
          age,
          gender,
          race,
          scr,
        }),
      );

      if (tempEGFR > 0) {
        egfr = tempEGFR;
      }
    }

    const newFormData = structuredClone({
      ...data,
      medicalHistory: {
        ...data.medicalHistory,
        vitalsReference: {
          ...data.medicalHistory?.vitalsReference!,
          vitals: {
            ...data.medicalHistory?.vitalsReference?.vitals!,
            scr,
            egfr,
          },
        },
      },
    });

    setFormData(JSON.parse(JSON.stringify(newFormData)));
    formDataRef.current = newFormData;
  };

  const onChangeForm = (data: FormDataInterface, id: string) => {
    if (['root_patientBasic_age', 'root_patientBasic_gender', 'root_patientBasic_ethnicity', 'root_medicalHistory_vitalsReference_vitals_scr'].includes(id)) {
      calculateEgfr(data);
    } else if (
      ['root_medicalHistory_vitalsReference_vitals_weight', 'root_medicalHistory_vitalsReference_vitals_height_feet', 'root_medicalHistory_vitalsReference_vitals_height_inches'].includes(id)
    ) {
      calculateBmi(data);
    }

    if (
      ['root_medicalHistory_hfType', 'root_physician_assignPhysician'].includes(id) &&
      data.physician.assignPhysician &&
      data.physician.assignPhysician.length !== 0 &&
      data.medicalHistory.hfType.toLowerCase() !== 'hfmref' &&
      data.medicalHistory.hfType.toLowerCase() !== 'hfimpef'
    ) {
      fetchUserPreset(data.physician.assignPhysician, data.medicalHistory.hfType, data);
    }

    console.log('adscasdjcnasdkcnasdkjcasjkcdsjkcnkjdckjjknadsc', data);

    formDataRef.current = data;
  };

  const onSubmit = async (data: any, event: any) => {
    console.log('submit datadata', data);

    formDataRef.current = data.formData;

    let updatedFormObj = {
      ...formDataRef.current,
    };

    if (formDataRef.current) {
      updatedFormObj = {
        ...formDataRef.current,
        previousMedications: {
          payload: {
            prescription: {
              prescriptionMedications: formDataRef?.current?.previousMedications || [],
            },
          },
          timestamp: DateTime.now().toFormat('yyyy-MM-dd'),
          type: 'PREVIOUS_MEDICATIONS',
        },
      };
    }

    if (document.getElementById('appointment-error')) {
      document.getElementById('appointment-error')?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (document.getElementById('next-review-date-error')) {
      document.getElementById('next-review-date-error')?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    setFormData(data.formData);

    const formObj = formatFormData({
      formData: updatedFormObj,
      symptoms: symptoms.patientSymptoms,
      weightUnit: organizatoinFields.vitalsUnit.weight,
    });

    setIsApiCalling(true);

    if (buttonRef.current?.click === 'save-draft') {
      buttonRef.current!.click = '';
      try {
        let patientSourceId = '';
        if (params.patientSourceId) {
          await updateEnrolledPatient(formObj, params.patientSourceId, PATIENT_DRAFT_STATUS);
          patientSourceId = params.patientSourceId;
          reduxDispatch(
            updateDraftPatientInfo({
              ...formObj,
              basicDetails: {
                ...formObj.basicDetails,
                patientSourceId: params.patientSourceId,
              },
            } as unknown as PatientListNewType),
          );
        } else {
          const resp = await enrollNewPatient(formObj, PATIENT_DRAFT_STATUS);
          patientSourceId = resp.data.patientSourceId;
        }

        navigate('..');

        _renderToast('Successfully saved draft!', formObj?.basicDetails?.firstName, formObj?.basicDetails?.lastName, formObj?.basicDetails?.patientId, `../${patientSourceId}/draft`);

        setEditDetails(mapObject(editDetails, () => true));
        sessionStorage.clear();
      } catch (error: any) {
        reduxDispatch(setToast({ code: 'error', message: error.response.data.error, isOpen: true }));
      }
    }

    if (buttonRef.current?.click === 'save-approve') {
      buttonRef.current!.click = '';
      try {
        let patientSourceId;
        if (params.patientSourceId) {
          await updateEnrolledPatient(formObj, params.patientSourceId, PATIENT_APPROVAL_STATUS);
          patientSourceId = params.patientSourceId;
        } else {
          const resp = await enrollNewPatient(formObj, PATIENT_APPROVAL_STATUS);
          patientSourceId = resp.data.patientSourceId;
        }

        _renderToast('Successfully submitted for approval!', formObj?.basicDetails?.firstName, formObj?.basicDetails?.lastName, formObj?.basicDetails?.patientId, `../${patientSourceId}/approve`);

        navigate('..');
        sessionStorage.clear();
      } catch (error: any) {
        console.log('error', error);
        reduxDispatch(setToast({ code: 'error', message: error.response.data.error, isOpen: true }));
      }
    }

    setIsApiCalling(false);
  };

  const handleClickSubmitApprove = async () => {
    if (submitApproveIsLoading) {
      return;
    }

    if (!params.patientSourceId) {
      return;
    }

    setSubmitApproveIsLoading(true);

    try {
      await approvePatient(params.patientSourceId);

      // Show success toast.
      reduxDispatch(setToast({ message: 'Successfully approved patient onboarding', code: 'success', isOpen: true }));

      navigate('..');

      setSubmitApproveIsLoading(false);
    } catch (error: any) {
      // Show error message.
      reduxDispatch(setToast({ message: error?.response?.data?.error, code: 'error', isOpen: true, id: Math.random() }));

      setSubmitApproveIsLoading(false);
    }
  };

  const handleSubmitPatientDeviceInfo = async (deviceId: string) => {
    setIsDeviceInfoUpdating(true);
    try {
      await updatePatientDeviceInfo(params.patientSourceId!, deviceId);
      reduxDispatch(setToast({ message: 'Successfully updated the device information', code: 'success', isOpen: true }));
    } catch (err) {
      reduxDispatch(setToast({ message: 'Failed to update the device information', code: 'error', isOpen: true }));
    }

    await awaitForSometime();
    await getUnmappedDevices();
    await getAllMappedDevices();

    setIsDeviceInfoUpdating(false);
  };

  const handleSubmitDeletePatientDeviceInfo = async (deviceInfo: PatientDevice) => {
    setIsDeviceInfoUpdating(true);
    try {
      await deletePatientDeviceInfo(params.patientSourceId!, deviceInfo?.deviceId!);
      reduxDispatch(setToast({ message: 'Successfully remove device information', code: 'success', isOpen: true }));
    } catch (err) {
      reduxDispatch(setToast({ message: 'Failed to remove device information', code: 'error', isOpen: true }));
    }
    await awaitForSometime();
    await getUnmappedDevices();
    await getAllMappedDevices();
    setIsDeviceInfoUpdating(false);
  };

  const handleCloseSettingDrawer = () => {
    setOpenSettingDrawer(false);
  };

  const handleClickOpenSettingDrawer = () => {
    setOpenSettingDrawer(true);
  };

  const _renderToast = (title: string, firstName: string, lastName: string, patientId: string, link: string) => {
    const id = new Date().getTime();
    reduxDispatch(
      setToast({
        code: 'success',
        id,
        message: title,
        html: (
          <Grid item xs={12} container alignItems='center' justifyContent='space-between' gap={3}>
            <Grid item xs>
              {firstName && lastName ? (
                <Typography
                  variant='fontBold18'
                  sx={{
                    marginRight: '5px',
                    display: 'inline-block',
                    width: 'inherit',
                    maxWidth: '350px',
                    whiteSpace: 'break-spaces',
                    overflow: 'auto !important',
                    textOverflow: 'ellipsis',
                  }}>
                  {firstName}&nbsp;{lastName}
                  &nbsp;({patientId})&nbsp; - {title}
                </Typography>
              ) : (
                <Typography variant='fontBold18'>
                  ({patientId})&nbsp; - {title}
                </Typography>
              )}
            </Grid>
            <Grid item xs='auto'>
              <Button
                onClick={() => {
                  navigate(link);
                  reduxDispatch(clearToast({ id }));
                }}>
                View
              </Button>
            </Grid>
          </Grid>
        ),
        noTimeout: true,
        isOpen: true,
      }),
    );
  };

  console.log('formDataRef.currentformDataRef.current', formDataRef.current, formData);

  const providerValue: PatientEnrolmentContextType = {
    organizatoinFields,

    tabPhysicianRef,
    tabPatientBasicDetailsRef,
    tabTCPRef,
    tabPatientRulesRef,
    tabPatientMedicalHistoryRef,
    buttonRef,

    handleChangeTabs,

    editDetails,
    handleClickEditDetails,

    loading,
    isApiCalling,

    symptomsIsLoading: symptoms.isLoading,

    allDoctors: doctors,
    formData,
    formDataRef,
    onChangeForm,

    pathname,

    customValidate,
    transformErrors,
    customElementScollToView,
    onSubmit,

    showApproveButton,

    isEditTCPMedications,
    setIsEditTCPMedications,

    organizationMedications: organizationMedications.medications,

    showCancelChangesDialog,
    onClickCancelSavedChanges,
    onClickHideCancelChangesConfirmationDialog,
    onClickShowCancelChangesConfirmationDialog,

    submitApproveIsLoading,
    handleClickSubmitApprove,

    unmappedDevices,
    mappedDevices,
    isDeviceInfoUpdating,
    openSettingDrawer,
    handleCloseSettingDrawer,
    handleClickOpenSettingDrawer,
    handleSubmitPatientDeviceInfo,
    handleSubmitDeletePatientDeviceInfo,
    prevMedicationRef,
  };

  return (
    <PatientEnrolmentContext.Provider value={providerValue}>
      <PatientEnrolment />
    </PatientEnrolmentContext.Provider>
  );
};

export default PatientEnrolmentContextProvider;
