/** @format */

import _ from 'underscore';
import * as React from 'react';
import { DateTime } from 'luxon';
import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import PatientProfile from './PatientProfile';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setToast } from '../../reducers/toast-reducer/ToastReducer';

import { PatientDeactivationReasonType, PatientDevice, PatientInfoType } from '../../types/Patients.types';
import { PatientTimeLineType } from '../../types/Timeline.types';
import { PatientTasksItemType } from '../../types/PatientTasks.type';
import { PatientVitalsGraphType } from '../../types/PatientListPage.types';
import { PatientVitalsReadingType } from '../../types/PatientVitals.types';
import { PatientSymptomsPayloadType } from '../../types/PatientSymptoms.types';
import { PatientMedicalHistoryPayloadType } from '../../types/PatientMedicalHistory.types';
import { PatientRuleItemThresholdType, PatientRuleItemType } from '../../types/PatientRule.types';
import { PatientAttachmentApiType, PatientAttachmentsFilterType, V2PatientAttachmentReportType } from '../../types/PatientAttachments.types';
import {
  PatientAlreadyPrescribedMedicationsType,
  PatientPrescriptionReviewListType,
  PatientPrescriptionsLogsType,
  PatientPrescriptionsReportType,
  PrescriptionsGeneratorMedicationsCommonItemType,
} from '../../types/PrescriptionsGenerator.types';

import { addPatientNote, deletePatientNote, getOrgUsers, getPatientNotes, updatePatientNote } from '../../service/notes';
import { getLogsHistoryByFilters, getPatientLogs } from '../../service/logs';
import { createPatientVitalsGraphPreference, getPatientVitalsGraphPreference, updatePatientVitalsGraphPreference } from '../../service/patient-preference';
import { fetchPatientEcg } from '../../service/patient-ecg';
import { fetchPatientMedicalEvents } from '../../service/patient-medical-events';
import { updatePatientMedicalAdherenceStatus } from '../../service/medical-adherence';
import { fetchPatientAlertNotification } from '../../service/patientAlertNotification';
import { updatePatientSymptoms } from '../../service/patient-symptoms';
import { deactivatePatient, fetchPatientInfo, updatePatientMedicalHistory } from '../../service/patient-info';
import { createPatientReport, deletePatientReport, fetchPatientAttachments, getPatientAttachmentsReports, updatePatientReport, uploadPatientAttachments } from '../../service/patient-attachments';
import { fetchPatientVitalsByRangeGraph, uploadPatientLabReports } from '../../service/patient-vitals';
import {
  createPatientPrescriptionReviewDraft,
  createPatientPrescriptionReviewSubmitApproval,
  deletePatientPrescriptionReview,
  fetchPatientPrescriptionLogs,
  getPatientPrescriptionReviewList,
  rejectPatientPrescriptionReview,
  updatePatientPrescriptionReviewDraft,
  updatePatientPrescriptionReviewSubmitApproval,
  updatePatientPrescriptions,
} from '../../service/patient-titrations-plan';
import { addPatientAppointment, deletePatientAppointment, getAllAppointments, updatePatientAppointment } from '../../service/appointments';
import {
  createNewPatientRule,
  deletePatientRuleById,
  getPatientActiveVideoCallStatus,
  getPatientRules,
  getPatientRulesThreshold,
  updatePatientRuleById,
  updatePatientRuleDefaultRule,
  updatePatientRuleThreshold,
} from '../../service/patient-rules';

import { VITALS_SOURCE_TYPE_APPLE_WATCH, VITALS_SOURCE_TYPE_EXCEPT_APPLE_WATCH } from '../../constants/PatientVitals';
import { AllSavedNotesType } from '../../types/Notes';
import { AppointmentsType } from '../../types/Appointments';
import { DropdownOptionType } from '../../types/CustomForm.types';
import { UserSessionContext } from '../../context/UserSession';
import { convertToRawText } from '../../utils/functions/notes';
import { updateDeactivePatientInfo } from '../../reducers/patient-list-reducer/DeactivePatientListSlice';
import { deletePatientDeviceInfo, fetchAllMappedDevices, fetchUnmappedDevices, updatePatientDeviceInfo } from '../../service/devices';
import { awaitForSometime } from '../../utils/functions/enrolment-form';

export const NOTE_FILTER_INITIAL_STATE = {
  noteCreatedBy: '',
  noteSearch: '',
  noteType: '',
  noteStartDate: DateTime.now().minus({ days: 14 }).toFormat('yyyy-MM-dd'),
  noteEndDate: DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd'),
  noteLimit: 10,
  toggleMyNotesOnly: false,
};

export type VitalHistoryFiltersType = {
  startDate: string;
  endDate: string;
  dateRangeView: 'WEEK' | 'MONTH';
};

export type PatientEcgFilterType = {
  startDate: string;
  endDate: string;
  deviceType: string;
  diagnosisType: string;
  pageLimit: number;
  pageOffset: number;
};

export type PatientNotesFilterType = {
  noteCreatedBy: string;
  noteSearch: string;
  noteType: string;
  noteStartDate: string;
  noteEndDate: string;
  noteLimit: number;
  toggleMyNotesOnly: boolean;
};

export type PatientLogHistoryFilterType = {
  pageLimit: number;
  pageOffset: number;
};
export type PatinetLogsFilterType = {
  pageOffset: number;
  pageLimit: number;
};

export interface PatientProfileContextProps {
  isAPILoading: boolean;
  fetchPatientInfoQuery: UseQueryResult<any, Error> | null;
  patientVideoCallStatus: any;
  setSelectedPdfUrl: (payload: string) => void;
  unmappedDevices: PatientDevice[];
  selectedPdfUrl: string;
  isDeviceInfoUpdating: boolean;
  patientLogsFilter: PatinetLogsFilterType;
  patientInfo: PatientInfoType;
  isPatinetProfileLogsLoading: boolean;
  patientPrescriptionLogs: PatientPrescriptionsLogsType[];
  nextReviewSuggestion: PrescriptionsGeneratorMedicationsCommonItemType[];
  patientAlreadyPrescribedMedications: PatientAlreadyPrescribedMedicationsType[];
  handleClickOnAddOrCancelPrescribedMedications: (medications: PatientAlreadyPrescribedMedicationsType, isAdd: boolean) => void;
  handleClickOnStopOrCancelPrescribedMedications: (medications: PatientAlreadyPrescribedMedicationsType, isStop: boolean) => void;
  isPatientPrescriptionUpdating: boolean;
  handleUpdatePatientPrescriptions: (medications: PatientPrescriptionsReportType) => void;
  mappedDevices: PatientDevice[];
  handleSubmitDeletePatientDeviceInfo: (deviceInfo: PatientDevice) => void;
  isEditPatientPrescriptions: boolean;
  toggleEditPatientPrescriptions: (isForceValue?: boolean, value?: boolean) => void;
  isPatientPrescriptionReviewListLoading: boolean;
  patientPrescriptionReviewList: { data: PatientPrescriptionReviewListType[]; count: number };
  prescriptionApprovalListFilters: { limit: number; offset: number; status: string[] };
  handleChangePrescriptionApprovalFilters: (value: any, variable: string) => void;
  handleSubmitPatientDeviceInfo: (value: string) => void;
  isUpdatingDraftPatientPrescriptionReview: boolean;
  handleSubmitDraftPrescriptionsReview: (medications: PatientPrescriptionsReportType, presciptionId?: string) => void;

  isUpdatingSubmitApprovalPrescriptionReview: boolean;
  handleSubmitApprovalPrescriptionsReview: (medications: PatientPrescriptionsReportType, presciptionId?: string) => void;

  isDeletePatientPrescriptionReview: boolean;
  handleSubmitDeletePrescriptionReview: (presciptionId: string) => void;

  isRejectPatientPrescriptionReview: boolean;
  handleSubmitRejectPrescriptionReview: (presciptions: PatientPrescriptionReviewListType, notes: string) => void;

  isLoadingPatientAttachments: boolean;
  isPatientAttachmentUploading: boolean;

  patientAttachments: PatientAttachmentApiType;
  patientAttachmentFilters: PatientAttachmentsFilterType;

  handleSubmitPatientAttachments: (file: any, type: string) => void;
  handleSubmitKccq: (medicalHistory: any) => void;
  handleSubmitWalkTest: (medicalHistory: any) => void;
  handleChangePatientAttachmentFilters: (file: any, type: string) => void;

  isUpdatingPatientRule: boolean;
  patientRules: PatientRuleItemType[];
  handleSubmitNewPatientRule: (rule: PatientRuleItemType) => void;
  handleSubmitChangeDefaultRules: () => void;
  handleSubmitUpdatePatientRule: (ruleId: string, rule: PatientRuleItemType) => void;
  handleSubmitDeletePatientRule: (ruleId: string) => void;

  isUpdatingPatientRuleThreshold: boolean;
  patientRuleThreshold: PatientRuleItemThresholdType[];

  handleSubmitUpdatePatientRuleThreshold: (rule: PatientRuleItemThresholdType[]) => void;

  isPatientMedicalHistoryUpdatingApiCalling: boolean;
  handleSubmitPatientMedicalHistory: (paylaod: Partial<PatientMedicalHistoryPayloadType>) => void;

  patientMedicationTask: PatientTasksItemType<any, any>[];
  isPatientVitalsHistoryLoading: boolean;
  vitalHistoryFilters: VitalHistoryFiltersType;

  patientVitalsHistory: PatientVitalsGraphType[];
  patientVitalsHistoryAppleWatch: PatientVitalsGraphType[];
  patientVitalsHistoryMedicalEvent: PatientTimeLineType[];
  handleChangeVitalHistoryFitlers: (filters: Partial<VitalHistoryFiltersType>) => void;

  isPatientEcgLoading: boolean;

  patientEcgFilter: PatientEcgFilterType;
  patientEcgs: { count: number; data: any[] };

  patientPreferenceVitalsGraphView: {
    dbp: boolean;
    sbp: boolean;
    spo2: boolean;
    steps: boolean;
    weight: boolean;
    hrApple: boolean;
    hrOmron: boolean;
    medicalEvents: boolean;

    medianLine: boolean;
  };

  isPatientPreferenceVitalsGraphViewLoading: boolean;

  handleSubmitPatientGraphPreference: (patientGraphPreference: any) => void;

  handleChangePatientEcgFilters: (filters: Partial<PatientEcgFilterType>) => void;

  isPatientLogsLoading: boolean;

  patientLogsHistoryFilter: PatientLogHistoryFilterType;
  patientLogsHistory: { logsData: any[]; count: number };
  patientLogsData: { logsData: object[]; count: number };

  handleChangePatientLogHistoryFilters: (filter: Partial<PatientLogHistoryFilterType>) => void;

  isAppointmentUpdating: boolean;
  appointmentList: AppointmentsType[];

  handleSubmitNewPatientAppointment: (payload: AppointmentsType) => void;
  handleSubmitUpdateAppointment: (payload: AppointmentsType) => void;
  handleSubmitDeleteAppointment: (payload: AppointmentsType) => void;

  handleClickSaveGeneralNote: (payload: AllSavedNotesType | any) => void;
  allSavedNotes: AllSavedNotesType[];
  profileSavedNotes: AllSavedNotesType[];
  handleClickEditPatientNote: (payload: AllSavedNotesType) => void;
  note: AllSavedNotesType | null;
  handleClickDeleteNote: (payload: string) => void;

  handleClickShowMoreNotes: () => void;
  noteCount: number;
  noteFilters: PatientNotesFilterType;
  handleChangeNoteFilters: (payload: {
    key: 'noteCreatedBy' | 'noteSearch' | 'noteType' | 'noteStartDate' | 'noteEndDate' | 'noteLimit' | 'toggleMyNotesOnly';
    value: string | number | boolean;
  }) => void;
  handleClickResetNoteFilters: () => void;
  notesIsLoading: boolean;
  allSavedNotesIsLoading: boolean;
  noteUsersOptions: DropdownOptionType[];
  handleClickToggleMyNotesOnly: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLabReportUpdatingApiCalling: boolean;
  handleSubmitLabReports: (payload: PatientVitalsReadingType[]) => void;

  handleChangePageOffset: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeLimit: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isMedicalAdherenceUpdatingApiCalling: boolean;
  handleSubmitMedicalAdherence: (payload: any) => void;

  isPatientSymptomsUpdatingApiCalling: boolean;
  handleSubmitPatientSymptoms: (payload: Omit<PatientSymptomsPayloadType, 'patientSourceId' | 'recordId' | 'sourceId'>) => void;

  isPatientDeactivationUpdating: boolean;
  handleSubmitPatientDeactivation: (deactivationReason: PatientDeactivationReasonType) => void;

  patientApprovalPrescriptions: PatientPrescriptionReviewListType | null;
  handleChangePatientApprovalPrescriptions: (item: PatientPrescriptionReviewListType | null) => void;

  isPatientAttachmentsUpdating: boolean;
  handleSubmitPatientAttachment: (payload: V2PatientAttachmentReportType) => void;
  handleSubmitUpdateAttachment: (payload: V2PatientAttachmentReportType) => void;
  handleDeletePatientReport: (payload: number | any) => void;

  isLoadingPatientAttachmentsReports: boolean;
  patientAttachmentsReportsData: [];
  refetchPatientDetails: any;
}

const defaultValue: PatientProfileContextProps = {
  handleSubmitDeletePatientDeviceInfo: console.info,
  isDeviceInfoUpdating: false,
  unmappedDevices: [],
  refetchPatientDetails: console.info,
  fetchPatientInfoQuery: null,
  setSelectedPdfUrl: console.info,
  selectedPdfUrl: '',
  isPatinetProfileLogsLoading: true,
  isAPILoading: true,
  patientVideoCallStatus: {},
  patientLogsFilter: { pageOffset: 0, pageLimit: 10 },
  patientInfo: {},
  patientPrescriptionLogs: [],
  handleSubmitPatientDeviceInfo: console.info,
  nextReviewSuggestion: [],
  patientAlreadyPrescribedMedications: [],
  handleChangePageOffset: console.info,
  handleChangeLimit: console.info,
  handleClickOnAddOrCancelPrescribedMedications: console.info,
  handleClickOnStopOrCancelPrescribedMedications: console.info,
  mappedDevices: [],
  isPatientPrescriptionUpdating: false,
  handleUpdatePatientPrescriptions: console.info,

  isEditPatientPrescriptions: false,
  toggleEditPatientPrescriptions: console.info,

  isPatientPrescriptionReviewListLoading: true,
  patientPrescriptionReviewList: { data: [], count: 0 },
  prescriptionApprovalListFilters: { limit: 30, offset: 0, status: [] },
  handleChangePrescriptionApprovalFilters: console.info,

  isUpdatingDraftPatientPrescriptionReview: false,
  handleSubmitDraftPrescriptionsReview: console.info,

  isUpdatingSubmitApprovalPrescriptionReview: false,
  handleSubmitApprovalPrescriptionsReview: console.info,

  isDeletePatientPrescriptionReview: false,
  handleSubmitDeletePrescriptionReview: console.info,

  isRejectPatientPrescriptionReview: false,
  handleSubmitRejectPrescriptionReview: console.info,

  isLoadingPatientAttachments: true,
  isPatientAttachmentUploading: false,

  patientAttachments: { data: [], count: 0 },
  patientAttachmentFilters: { type: 'ALL', limit: 10, offset: 0 },

  handleSubmitPatientAttachments: console.info,
  handleSubmitKccq: console.info,
  handleSubmitWalkTest: console.info,
  handleChangePatientAttachmentFilters: console.info,

  isUpdatingPatientRule: false,
  patientRules: [],
  handleSubmitNewPatientRule: console.info,
  handleSubmitChangeDefaultRules: console.info,
  handleSubmitDeletePatientRule: console.info,
  handleSubmitUpdatePatientRule: console.info,

  isUpdatingPatientRuleThreshold: false,
  patientRuleThreshold: [],
  handleSubmitUpdatePatientRuleThreshold: console.info,

  patientMedicationTask: [],

  isPatientMedicalHistoryUpdatingApiCalling: false,
  handleSubmitPatientMedicalHistory: console.info,

  isPatientVitalsHistoryLoading: true,

  vitalHistoryFilters: { startDate: DateTime.local().toISO()!, endDate: DateTime.local().toISO()!, dateRangeView: 'WEEK' },
  patientVitalsHistory: [],
  patientVitalsHistoryAppleWatch: [],
  patientVitalsHistoryMedicalEvent: [],

  handleChangeVitalHistoryFitlers: console.info,

  isPatientEcgLoading: true,

  patientEcgFilter: { startDate: DateTime.local().toISO()!, endDate: DateTime.local().toISO()!, diagnosisType: 'All Diagnosis', deviceType: 'All Devices', pageLimit: 5, pageOffset: 0 },
  patientEcgs: { count: 0, data: [] },

  handleChangePatientEcgFilters: console.info,

  patientPreferenceVitalsGraphView: {
    dbp: true,
    sbp: true,
    spo2: true,
    steps: true,
    weight: true,
    hrApple: true,
    hrOmron: true,
    medicalEvents: true,

    medianLine: true,
  },
  isPatientPreferenceVitalsGraphViewLoading: true,

  handleSubmitPatientGraphPreference: console.log,

  isPatientLogsLoading: true,

  patientLogsHistoryFilter: { pageLimit: 10, pageOffset: 0 },
  patientLogsHistory: { logsData: [], count: 0 },
  patientLogsData: { logsData: [], count: 0 },

  handleChangePatientLogHistoryFilters: console.info,

  isAppointmentUpdating: false,

  appointmentList: [],
  handleSubmitNewPatientAppointment: console.info,
  handleSubmitUpdateAppointment: console.info,
  handleSubmitDeleteAppointment: console.info,

  handleClickSaveGeneralNote: console.info,
  allSavedNotes: [],
  profileSavedNotes: [],
  handleClickDeleteNote: console.info,

  note: null,
  handleClickEditPatientNote: console.info,
  handleClickShowMoreNotes: console.info,
  noteCount: 0,
  noteFilters: NOTE_FILTER_INITIAL_STATE,
  handleChangeNoteFilters: console.info,
  handleClickResetNoteFilters: console.info,
  notesIsLoading: false,
  allSavedNotesIsLoading: false,
  noteUsersOptions: [],
  handleClickToggleMyNotesOnly: console.info,

  isLabReportUpdatingApiCalling: false,
  handleSubmitLabReports: console.info,

  isMedicalAdherenceUpdatingApiCalling: false,
  handleSubmitMedicalAdherence: console.info,

  isPatientSymptomsUpdatingApiCalling: false,
  handleSubmitPatientSymptoms: console.info,

  isPatientDeactivationUpdating: false,
  handleSubmitPatientDeactivation: console.info,

  patientApprovalPrescriptions: null,
  handleChangePatientApprovalPrescriptions: console.info,

  isPatientAttachmentsUpdating: false,
  handleSubmitPatientAttachment: console.info,
  handleSubmitUpdateAttachment: console.info,
  handleDeletePatientReport: console.info,

  isLoadingPatientAttachmentsReports: false,
  patientAttachmentsReportsData: [],
};

export const PatientProfileContext = React.createContext(defaultValue);

interface PatientListProviderProps {}

const PatientListProvider: React.FunctionComponent<PatientListProviderProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { patientSourceId }: any = useParams();
  const reduxDispatch = useAppDispatch();
  const { userSession } = React.useContext(UserSessionContext);

  const { showVideoCall, onGoingVideoCall } = useAppSelector((state) => state.userRegion);

  const [patientInfo, setPatientInfo] = React.useState<PatientInfoType>({});

  const [patientAttachmentFilters, setPatientAttachmentFilters] = React.useState<PatientAttachmentsFilterType>({ limit: 10, offset: 0, type: 'ALL' });
  const [mappedDevices, setAllMappedDevices] = React.useState<PatientDevice[]>([]);
  const [unmappedDevices, setUnMappedDevices] = React.useState<PatientDevice[]>([]);
  const [isDeviceInfoUpdating, setIsDeviceInfoUpdating] = React.useState<boolean>(false);
  const [appointmentList, setAppointmentList] = React.useState<AppointmentsType[]>([]);
  const [patientVideoCallStatus, setPatientVideoCallStatus] = React.useState<any>({});
  const [patientVitalsHistoryMedicalEvent, setPatientVitalsHistoryMedicalEvent] = React.useState<PatientTimeLineType[]>([]);
  const [vitalHistoryFilters, setVitalHistoryFilters] = React.useState<VitalHistoryFiltersType>({
    startDate: DateTime.local().minus({ days: 13 }).toISO()!,
    endDate: DateTime.local().toISO()!,
    dateRangeView: 'WEEK',
  });

  const [patientEcgFilter, setPatientEcgFilters] = React.useState<PatientEcgFilterType>({
    startDate: DateTime.local().minus({ months: 1 }).toISO()!,
    endDate: DateTime.local().toISO()!,
    diagnosisType: 'All Diagnosis',
    deviceType: 'All Devices',
    pageLimit: 5,
    pageOffset: 0,
  });

  const [patientLogsFilter, setPatientLogsFilter] = React.useState<PatinetLogsFilterType>({
    pageOffset: 0,
    pageLimit: 10,
  });
  const [selectedPdfUrl, setSelectedPdfUrl] = React.useState<string>('');
  const [patientLogsHistoryFilter, setPatientLogsHistoryFilters] = React.useState<PatientLogHistoryFilterType>({ pageLimit: 10, pageOffset: 0 });

  const [patientAlreadyPrescribedMedications, setPatientAlreadyPrescribedMedications] = React.useState<PatientAlreadyPrescribedMedicationsType[]>([]);

  const [noteFilters, setNoteFilters] = React.useState<PatientNotesFilterType>(NOTE_FILTER_INITIAL_STATE);
  const [note, setNote] = React.useState<AllSavedNotesType | null>(null);

  const [isEditPatientPrescriptions, setIsEditPatientPrescriptions] = React.useState<boolean>(false);

  const [prescriptionApprovalListFilters, setPrescriptionApprovalListFilters] = React.useState<{ limit: number; offset: number; status: string[] }>({ limit: 20, offset: 0, status: [] });

  const [patientApprovalPrescriptions, setPatientApprovalPrescriptions] = React.useState<PatientPrescriptionReviewListType | null>(null);

  const getAllAppointmentBasedOnPaginations = async (limit = 1000, offset = 0) => {
    return getAllAppointments(patientSourceId, undefined, undefined, limit, offset);
  };

  // apis
  const getOrgUsersQuery = useQuery({ queryKey: ['getOrgUsers', 'ALL'], queryFn: () => getOrgUsers('ALL') });
  const fetchPatientInfoQuery = useQuery({
    queryKey: ['fetchPatientInfo', patientSourceId],
    queryFn: async () => {
      const data = await fetchPatientInfo(patientSourceId, { isEnrolmentDetails: true });
      const physicianInfo = getOrgUsersQuery.data?.find((e: any) => e.userId === fetchPatientInfoQuery.data?.basicDetails?.cardiologistId);
      data.basicDetails = {
        ...data?.basicDetails,
        physicianName: physicianInfo?.user?.name,
      };
      setPatientInfo(data);
      return { ...data };
    },
  });

  const fetchAllMappedQuery = useQuery({
    queryKey: ['fetchMappedDevices'],
    queryFn: async () => {
      const data = await fetchAllMappedDevices();
      setAllMappedDevices(JSON.parse(JSON.stringify(data)));
    },
  });

  const fetchAllUnMappedQuery = useQuery({
    queryKey: ['fetchUnMappedDevices'],
    queryFn: async () => {
      const data = await fetchUnmappedDevices();
      setUnMappedDevices(JSON.parse(JSON.stringify(data)));
    },
  });

  const fetchPatientPrescriptionLogsQuery = useQuery({
    queryKey: ['fetchPatientPrescriptionLogs', patientSourceId],
    queryFn: async () => {
      let prescriptionsLogs: PatientPrescriptionsLogsType[] = [];
      let startLoop = true;
      const limit = 20;
      let offset = 0;
      while (startLoop) {
        const res = await fetchPatientPrescriptionLogs(patientSourceId, limit, offset);
        prescriptionsLogs = prescriptionsLogs.concat(res.prescriptionsLogs);
        startLoop = res.count > prescriptionsLogs.length;
        offset += limit;
      }

      return prescriptionsLogs;
    },
  });

  const fetchPatientAttachmentsQuery = useQuery({
    queryKey: ['fetchPatientAttachments', patientSourceId, patientAttachmentFilters.type, patientAttachmentFilters.limit, patientAttachmentFilters.offset],
    queryFn: () => fetchPatientAttachments(patientSourceId, patientAttachmentFilters.type, patientAttachmentFilters.limit, patientAttachmentFilters.limit * patientAttachmentFilters.offset),
  });

  const fechPatientRulesQuery = useQuery({
    queryKey: ['fetchPatientRules', patientSourceId],
    queryFn: () => getPatientRules(patientSourceId),
  });

  const fechPatientRulesThresholdQuery = useQuery({
    queryKey: ['fetchPatientRulesThreshold', patientSourceId],
    queryFn: async () => {
      const rules = await getPatientRulesThreshold(patientSourceId);
      return rules?.threshold;
    },
  });

  const fetchPatientMedicationTasksQuery = useQuery({
    queryKey: ['fetchPatientMedicationTasks', patientSourceId],
    queryFn: async () => fetchPatientAlertNotification(patientSourceId, 'MEDICATION_TASKS', '50', '0', 'MAX'),
  });

  const fetchPatientVitalsByRangeGraphQuery = useQuery({
    queryKey: ['fetchPatientVitalsByRangeGraph', patientSourceId, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate],
    queryFn: async () => fetchPatientVitalsByRangeGraph(patientSourceId, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate, VITALS_SOURCE_TYPE_EXCEPT_APPLE_WATCH),
  });

  const fetchHistoryLogsQuery = useQuery({
    queryKey: ['fetchHistoryLogsQuery', patientSourceId, patientLogsHistoryFilter.pageLimit, patientLogsHistoryFilter.pageOffset],
    queryFn: async () => getLogsHistoryByFilters(patientSourceId, patientLogsHistoryFilter.pageLimit, patientLogsHistoryFilter.pageOffset * patientLogsHistoryFilter.pageLimit),
  });

  const fetchPatientVitalsAppleWatchByRangeGraphQuery = useQuery({
    queryKey: ['fetchPatientVitalsAppleWatchByRangeGraphQuery', patientSourceId, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate],
    queryFn: async () => fetchPatientVitalsByRangeGraph(patientSourceId, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate, VITALS_SOURCE_TYPE_APPLE_WATCH),
  });

  const fetchPatientVitalsMedicalEventsQuery = useQuery({
    queryKey: ['fetchPatientVitalsMedicalEventsQuery', patientSourceId, patientInfo?.basicDetails?.enrollmentDate],
    queryFn: async () => {
      const report = await fetchPatientMedicalEvents(patientSourceId, new Date(patientInfo?.basicDetails?.enrollmentDate as string).toISOString(), new Date().toISOString());
      setPatientVitalsHistoryMedicalEvent(report);
      return report;
    },
    enabled: !_.isEmpty(fetchPatientInfoQuery?.data),
  });

  //  calling api for checking patient active or not
  const fetchPatientActiveOnVideoCallQuery = useQuery({
    queryKey: ['fetchPatientActiveOnVideoCallQuery', patientSourceId],
    queryFn: async () => {
      const patientVideoCallStatus = await getPatientActiveVideoCallStatus(patientSourceId);
      setPatientVideoCallStatus(patientVideoCallStatus);
      return patientVideoCallStatus;
    },
  });

  const fetchPatientNotesQuery = useQuery({
    queryKey: [
      'fetchPatientNotesQuery',
      patientSourceId,
      noteFilters.noteLimit,
      noteFilters.noteCreatedBy,
      noteFilters.noteSearch.toLowerCase().trim(),
      noteFilters.noteType,
      noteFilters.noteStartDate,
      noteFilters.noteEndDate,
      noteFilters.toggleMyNotesOnly,
    ],
    queryFn: async (): Promise<{ data: any[]; count: number }> => {
      return await getPatientNotes({
        sourceId: patientSourceId,
        limit: noteFilters.noteLimit,
        createdBy: noteFilters.toggleMyNotesOnly ? userSession.user.userId : noteFilters.noteCreatedBy,
        search: noteFilters.noteSearch.toLowerCase().trim(),
        type: noteFilters.noteType,
        startDate: noteFilters.noteStartDate,
        endDate: noteFilters.noteEndDate,
      });
    },
  });
  const getPatientLogsQuery = useQuery({
    queryKey: ['getPatientLogsQuery', patientSourceId, patientLogsFilter.pageLimit, patientLogsFilter.pageOffset],
    queryFn: async () => getPatientLogs(patientSourceId, patientLogsFilter.pageLimit, patientLogsFilter.pageOffset * patientLogsFilter.pageLimit),
  });

  const fetchPatientGraphPreference = useQuery({
    queryKey: ['getPatientGraphPreference', patientSourceId],
    queryFn: async () => getPatientVitalsGraphPreference(patientSourceId),
  });

  const updatePatientGraphPreference = useMutation({
    mutationFn: ({ patientGraphPreference }: { patientGraphPreference: any }): Promise<any> => {
      if (_.isEmpty(fetchPatientGraphPreference.data)) {
        return createPatientVitalsGraphPreference(patientSourceId, patientGraphPreference);
      }

      return updatePatientVitalsGraphPreference(patientSourceId, patientGraphPreference);
    },
    onSuccess: () => {
      fetchPatientGraphPreference.refetch();
      reduxDispatch(setToast({ message: 'Successfully updated graph preference.', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to update graph preference.', code: 'error' }));
    },
  });

  const handleSubmitPatientGraphPreference = (patientGraphPreference: any) => {
    updatePatientGraphPreference.mutateAsync({ patientGraphPreference });
  };

  const fetchPatientEcgQuery = useQuery({
    queryKey: [
      'fetchPatientEcgQuery',
      patientSourceId,
      patientEcgFilter.startDate,
      patientEcgFilter.endDate,
      patientEcgFilter.deviceType,
      patientEcgFilter.diagnosisType,
      patientEcgFilter.pageLimit,
      patientEcgFilter.pageOffset,
    ],
    queryFn: async () =>
      fetchPatientEcg(
        patientSourceId,
        new Date(patientEcgFilter.startDate),
        new Date(patientEcgFilter.endDate),
        patientEcgFilter.deviceType,
        patientEcgFilter.diagnosisType,
        patientEcgFilter.pageLimit,
        patientEcgFilter.pageOffset * patientEcgFilter.pageLimit,
      ),
  });

  const fetchPatientAppointmentsQuery = useQuery({
    queryKey: ['fetchAllAppointmentsQuery', patientSourceId],
    queryFn: async () => {
      let startLoop = true;
      const limit = 1000;
      let offset = 0;

      let appointments: AppointmentsType[] = [];

      while (startLoop) {
        const data = await getAllAppointmentBasedOnPaginations(limit, offset);

        if (data.data) {
          appointments = appointments.concat(
            data.data.map((e: any) => {
              return {
                ...e.appointment,
                patientDetails: {
                  ...e.patientBasic,
                },
              };
            }),
          );
        }

        startLoop = data.count !== appointments.length;

        offset += limit;
      }

      setAppointmentList(appointments);
      return appointments;
    },
  });

  const fetchPatientPrescriptionReviewListQuery = useQuery({
    queryKey: ['fetchPatientPrescriptionReviewListQuery', patientSourceId, prescriptionApprovalListFilters.status, prescriptionApprovalListFilters.limit, prescriptionApprovalListFilters.offset],
    queryFn: async () => {
      const res = await getPatientPrescriptionReviewList(
        patientSourceId,
        prescriptionApprovalListFilters.status,
        prescriptionApprovalListFilters.limit,
        prescriptionApprovalListFilters.limit * prescriptionApprovalListFilters.offset,
      );
      return res;
    },
  });

  const updatePatientAttachmentsMutation = useMutation({
    mutationFn: ({ fileUpload, type }: { fileUpload: any; type: string }) => {
      return uploadPatientAttachments(patientSourceId, fileUpload, type);
    },
    onSuccess: () => {
      fetchPatientAttachmentsQuery.refetch();
      reduxDispatch(setToast({ message: 'Successfully upload the attachments', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to upload the attachments', code: 'error' }));
    },
  });

  const updatePatientDeviceInfoMutation = useMutation({
    mutationFn: ({ patientSourceId, deviceId }: { patientSourceId: string; deviceId: string }) => {
      return updatePatientDeviceInfo(patientSourceId, deviceId);
    },
    onSuccess: () => {
      fetchAllMappedQuery.refetch();
      fetchAllUnMappedQuery.refetch();
      awaitForSometime();
      setIsDeviceInfoUpdating(false);
      reduxDispatch(setToast({ message: 'Successfully updated the device information', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to update the device information', code: 'error' }));
    },
  });

  const deletePatientDeviceInfoMutation = useMutation({
    mutationFn: ({ patientSourceId, deviceId }: { patientSourceId: string; deviceId: string }) => {
      return deletePatientDeviceInfo(patientSourceId, deviceId);
    },
    onSuccess: () => {
      fetchAllMappedQuery.refetch();
      fetchAllUnMappedQuery.refetch();
      awaitForSometime();
      setIsDeviceInfoUpdating(false);
      reduxDispatch(setToast({ message: 'Successfully remove device information', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to remove device information', code: 'error' }));
    },
  });

  const updateKccqMutation = useMutation({
    mutationFn: ({ medicalHistory }: { medicalHistory: any }) => {
      return updatePatientMedicalHistory(patientSourceId, medicalHistory);
    },
    onSuccess: () => {
      fetchPatientInfoQuery.refetch();

      reduxDispatch(setToast({ code: 'success', message: 'Successfully added KCCQ Score' }));
    },
    onError: () => {
      reduxDispatch(setToast({ code: 'error', message: 'Failed to add KCCQ Score' }));
    },
  });

  const updateWalkTestMutation = useMutation({
    mutationFn: ({ medicalHistory }: { medicalHistory: any }) => {
      return updatePatientMedicalHistory(patientSourceId, medicalHistory);
    },
    onSuccess: () => {
      fetchPatientInfoQuery.refetch();

      reduxDispatch(setToast({ code: 'success', message: 'Successfully added Six Minute Walk Test' }));
    },
    onError: () => {
      reduxDispatch(setToast({ code: 'error', message: 'Failed to add Six Minute Walk Test' }));
    },
  });

  const updatePatientRulesChangeDefaultRulesMutation = useMutation({
    mutationFn: () => {
      return updatePatientRuleDefaultRule(patientSourceId);
    },
    onSuccess: () => {
      fechPatientRulesQuery.refetch();
      reduxDispatch(setToast({ message: 'Successfully created Patient Rule', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to create Patient Rule', code: 'error' }));
    },
  });

  const updatePatientRuleMutation = useMutation({
    mutationFn: ({ ruleId, rule }: { ruleId: string; rule: PatientRuleItemType }) => {
      return updatePatientRuleById(patientSourceId, ruleId, rule);
    },
    onSuccess: () => {
      fechPatientRulesQuery.refetch();
      reduxDispatch(setToast({ message: 'Successfully updated Patient Rule', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to update Patient Rule', code: 'error' }));
    },
  });

  const deletePatientRuleMutation = useMutation({
    mutationFn: ({ ruleId }: { ruleId: string }) => {
      return deletePatientRuleById(patientSourceId, ruleId);
    },
    onSuccess: (ruleId) => {
      fetchPatientAttachmentsReports.refetch();
      reduxDispatch(setToast({ message: 'Successfully delete Patient Rule', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to delete Patient Rule', code: 'error' }));
    },
  });

  const updatePatientRuleThresholdMutation = useMutation({
    mutationFn: ({ rule }: { rule: PatientRuleItemThresholdType[] }) => {
      return updatePatientRuleThreshold(patientSourceId, rule);
    },
    onSuccess: (rule) => {
      fechPatientRulesThresholdQuery.refetch();
      reduxDispatch(setToast({ message: 'Successfully updated Patient Rule threshold', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to update Patient Rule', code: 'error' }));
    },
  });

  const createPatientRuleThresholdMutation = useMutation({
    mutationFn: async ({ rule }: { rule: PatientRuleItemType }) => {
      await createNewPatientRule(patientSourceId, rule);
      fechPatientRulesQuery.refetch();
    },
    onSuccess: (rule) => {
      reduxDispatch(setToast({ message: 'Successfully created Patient Rule', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to create new Patient Rule', code: 'error' }));
    },
  });

  const updatePatientMedicalHistoryMutation = useMutation({
    mutationFn: async ({ payload }: { payload: Partial<PatientMedicalHistoryPayloadType> }) => {
      await updatePatientMedicalHistory(patientSourceId, payload);
      setPatientInfo((prev) => ({
        ...prev,
        medicalHistory: {
          ...prev.medicalHistory,
          ...(payload as PatientMedicalHistoryPayloadType),
        },
      }));
      return { patientSourceId };
    },
    onSuccess: (rule) => {
      reduxDispatch(setToast({ message: 'Successfully Updated', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to update Medical History', code: 'error' }));
    },
  });

  const addPatientAppointmentMutation = useMutation({
    mutationFn: async ({ payload }: { payload: AppointmentsType }) => {
      const newPayload = { ...payload };

      if (newPayload?.patientDetails) {
        newPayload.patientDetails = {} as any;
      }

      const data = await addPatientAppointment(patientSourceId, newPayload);
      console.log('datadata --- ', data);
      setAppointmentList((prev: AppointmentsType[]) => [{ ...payload, status: 'UPCOMING', appointmentId: data.appointmentId }].concat([...(prev as any)]));
      return { patientSourceId, payload };
    },
    onSuccess: () => {
      fetchPatientAppointmentsQuery.refetch();
      reduxDispatch(setToast({ message: 'Successfully added appointments', code: 'success' }));
    },
    onError: (error: any) => {
      reduxDispatch(setToast({ message: error.response.data.error, code: 'error' }));
    },
  });

  const updatePatientAppointmentMutation = useMutation({
    mutationFn: async ({ payload }: { payload: AppointmentsType }) => {
      const newPayload = { ...payload };

      console.log(payload);

      if (newPayload?.patientDetails) {
        newPayload.patientDetails = {} as any;
      }

      await updatePatientAppointment(payload.patientDetails.patientSourceId, payload.appointmentId!, newPayload);
      setAppointmentList((prev) =>
        prev.map((item) => {
          if (item.appointmentId === payload.appointmentId) {
            return payload;
          }
          return item;
        }),
      );
      return { patientSourceId, payload };
    },
    onSuccess: () => {
      fetchPatientAppointmentsQuery.refetch();
      reduxDispatch(setToast({ message: 'Successfully updated appointments', code: 'success' }));
    },
    onError: (error: any) => {
      reduxDispatch(setToast({ message: error.response.data.error, code: 'error' }));
    },
  });

  const deletePatientAppointmentMutation = useMutation({
    mutationFn: async ({ appointmentId }: { appointmentId: string }) => {
      console.log('patientSourceIdpatientSourceId', patientSourceId, appointmentId);

      await deletePatientAppointment(patientSourceId, appointmentId);
      setAppointmentList((prev) => prev.filter((item) => item.appointmentId !== appointmentId));
      return { patientSourceId, appointmentId };
    },
    onSuccess: () => {
      fetchPatientAppointmentsQuery.refetch();
      reduxDispatch(setToast({ message: 'Successfully deleted appointments', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to update the appointments', code: 'error' }));
    },
  });

  const updatePatientNotesMutation = useMutation({
    mutationFn: async ({ patientSourceId, payload }: { patientSourceId: string; payload: any }) => {
      const rawText = convertToRawText(payload.notesPayload);

      const newPayload = {
        ...payload,
        payload: payload.notesPayload,
        rawText,
      };
      await updatePatientNote(patientSourceId, payload.noteId, newPayload);
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully updated note', code: 'success' }));
      fetchPatientNotesQuery.refetch();
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to update note', code: 'error' }));
    },
  });

  const addPatientNotesMutation = useMutation({
    mutationFn: async ({ patientSourceId, payload }: { patientSourceId: string; payload: any }) => {
      const rawText = convertToRawText(payload);
      await addPatientNote(patientSourceId, {
        payload,
        rawText,
        type: 'GENERAL_NOTE',
      });
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully added a new note', code: 'success' }));
      fetchPatientNotesQuery.refetch();
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to add a note', code: 'error' }));
    },
  });

  const deletePatientNotesMutation = useMutation({
    mutationFn: async ({ patientSourceId, payload }: { patientSourceId: string; payload: string }) => {
      await deletePatientNote(patientSourceId, payload);
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully deleted note', code: 'success' }));
      fetchPatientNotesQuery.refetch();
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to delete note', code: 'error' }));
    },
  });

  const deleteReportMutation = useMutation({
    mutationFn: async ({ patientSourceId, payload }: { patientSourceId: string; payload: number }) => {
      await deletePatientReport(patientSourceId, payload);
    },
    onSuccess: () => {
      fetchPatientAttachmentsReports.refetch();
      reduxDispatch(setToast({ message: 'Successfully deleted Report', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to delete Report', code: 'error' }));
    },
  });

  const patientPrescriptionReviewDraftMutation = useMutation({
    mutationFn: async ({ payload, reviewId }: { payload: PatientPrescriptionsReportType; reviewId?: string }) => {
      if (reviewId) {
        await updatePatientPrescriptionReviewDraft(patientSourceId, reviewId, payload);
      } else {
        await createPatientPrescriptionReviewDraft(patientSourceId, payload);
      }
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully drafted patient Prescriptions', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to draft the prescription', code: 'error' }));
    },
  });

  const patientPrescriptionReviewSubmitApprovalMutation = useMutation({
    mutationFn: async ({ payload, reviewId }: { payload: PatientPrescriptionsReportType; reviewId?: string }) => {
      if (reviewId) {
        await updatePatientPrescriptionReviewSubmitApproval(patientSourceId, reviewId, payload);
      } else {
        await createPatientPrescriptionReviewSubmitApproval(patientSourceId, payload);
      }
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully submitted patient Prescriptions for approval', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to submit the prescription for approval', code: 'error' }));
    },
  });

  const deletePatientPrescriptionReviewMutation = useMutation({
    mutationFn: async ({ prescriptionId }: { prescriptionId: string }) => {
      await deletePatientPrescriptionReview(patientSourceId, prescriptionId);
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully deleted patient Prescriptions', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to delete the prescription', code: 'error' }));
    },
  });

  const rejectPatientPrescriptionReviewMutation = useMutation({
    mutationFn: async ({ reviewId, notes, prescriptions }: { reviewId: string; prescriptions: PatientPrescriptionsReportType; notes: string }) => {
      await rejectPatientPrescriptionReview(patientSourceId, reviewId, prescriptions, notes);
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully deleted patient Prescriptions', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to delete the prescription', code: 'error' }));
    },
  });

  const deactivationOfPatientMutation = useMutation({
    mutationFn: async ({ deactivationReason }: { deactivationReason: PatientDeactivationReasonType }) => {
      await deactivatePatient(patientSourceId, deactivationReason);
      setPatientInfo((prev) => ({ ...prev, basicDetails: { ...prev.basicDetails!, patientStatus: 'DEACTIVE' } }));
      reduxDispatch(updateDeactivePatientInfo(patientInfo));
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully deactivation of Patient', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to deactive the Patient', code: 'error' }));
    },
  });

  const formattedSavedNotes = React.useMemo(() => {
    return fetchPatientNotesQuery.data?.data.map((e: any) => {
      const currentUser = getOrgUsersQuery.data?.find((f: any) => f.user.userId === e.createdBy.userId);

      if (!currentUser) {
        return e;
      }

      if (e.createdBy.userId === currentUser.user.userId) {
        return {
          ...e,
          createdBy: {
            ...e.createdBy,
            name: currentUser.user.name,
          },
        };
      } else {
        return e;
      }
    });
  }, [fetchPatientNotesQuery.data, getOrgUsersQuery.data]);

  const handleClickToggleMyNotesOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked || event.target.checked) {
      setNoteFilters({
        ...noteFilters,
        noteCreatedBy: '',
      });
    }

    setNoteFilters({
      ...noteFilters,
      toggleMyNotesOnly: event.target.checked,
    });
  };

  const handleChangeNoteFilters = (payload: {
    key: 'noteCreatedBy' | 'noteSearch' | 'noteType' | 'noteStartDate' | 'noteEndDate' | 'noteLimit' | 'toggleMyNotesOnly';
    value: string | number | boolean;
  }) => {
    const { key, value } = payload;

    setNoteFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleClickResetNoteFilters = () => {
    setNoteFilters(NOTE_FILTER_INITIAL_STATE);
  };

  const updatePatientLabReportMutation = useMutation({
    mutationFn: async ({ payload }: { payload: PatientVitalsReadingType[] }) => {
      await uploadPatientLabReports(patientSourceId, payload);

      const timeine: PatientTimeLineType = {
        patientSourceId,
        type: 'LAB_REPORTS',
        medicalEventId: 'NEW_MEDICAL_EVENT',
        timestamp: payload[0].timestamp as string,
        payload: {
          vitals: payload,
          timestamp: payload[0].timestamp as string,
        },
      } as PatientTimeLineType;

      setPatientVitalsHistoryMedicalEvent((prev) => prev.concat([timeine]));

      setPatientInfo((prev) => ({
        ...prev,
        latestVitals: prev.latestVitals?.concat(payload),
      }));

      setTimeout(() => {
        fetchPatientVitalsMedicalEventsQuery.refetch();
      }, 1000);
      return { patientSourceId, payload };
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully Uploaded Lab-Reports', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to Upload Lab-Reports', code: 'error' }));
    },
  });

  const updatePatientMedicationAdherenceMutation = useMutation({
    mutationFn: async ({ payload }: { payload: any[] }) => {
      await updatePatientMedicalAdherenceStatus(patientSourceId, payload);
      return { patientSourceId, payload };
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully Uploaded Medication adherence', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to Upload Medication adherence', code: 'error' }));
    },
  });

  const updatePatientSymptomsMutation = useMutation({
    mutationFn: async ({ payload }: { payload: Omit<PatientSymptomsPayloadType, 'patientSourceId' | 'recordId' | 'sourceId'> }) => {
      await updatePatientSymptoms(patientSourceId, payload);

      setPatientInfo((prev) => ({
        ...prev,
        titrationCycleSymptoms: prev.titrationCycleSymptoms?.concat(payload as PatientSymptomsPayloadType),
      }));

      return { patientSourceId, payload };
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully Uploaded Patient Symptoms', code: 'success' }));
      setTimeout(() => {
        fetchPatientVitalsMedicalEventsQuery.refetch();
      }, 100);
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to Uploaded Patient Symptoms', code: 'error' }));
    },
  });

  const updatePatientPrescriptionsMutation = useMutation({
    mutationFn: async ({ payload }: { payload: PatientPrescriptionsReportType }) => {
      await updatePatientPrescriptions(patientSourceId, payload);

      return { patientSourceId, payload };
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully uploaded the Patient Prescriptions', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Fail to upload the Patient Prescriptions', code: 'error' }));
    },
  });

  const fetchPatientAttachmentsReports = useQuery({
    queryKey: ['fetchPatientAttachmentsReports', patientSourceId],
    queryFn: async () => getPatientAttachmentsReports(patientSourceId),
  });

  const addPatientAttachmentsReportsMutation = useMutation({
    mutationFn: async ({ patientSourceId, payload }: { patientSourceId: string; payload: any }) => {
      return await createPatientReport(patientSourceId, payload);
    },
    onSuccess: () => {
      fetchPatientAttachmentsReports.refetch();
      reduxDispatch(setToast({ message: 'Successfully Added a New Report', code: 'success' }));
    },
    onError: (error: any) => {
      const allErrors = error.response?.data?.error?.replace(/"/g, '');
      reduxDispatch(setToast({ message: allErrors, code: 'error' }));
    },
  });

  const updatePatientAttachmentsReportsMutation = useMutation({
    mutationFn: async ({ patientSourceId, payload, reportId }: { patientSourceId: string; payload: any; reportId: string | any }) => {
      return await updatePatientReport(patientSourceId, payload, reportId);
    },
    onSuccess: () => {
      fetchPatientAttachmentsReports.refetch();
      reduxDispatch(setToast({ message: 'Successfully updated Report', code: 'success' }));
    },
    onError: (error: any) => {
      const allErrors = error.response?.data?.error?.replace(/"/g, '');
      reduxDispatch(setToast({ message: allErrors, code: 'error' }));
    },
  });

  const handleSubmitPatientAttachmentReport = async (payload: V2PatientAttachmentReportType) => {
    await addPatientAttachmentsReportsMutation.mutateAsync({ patientSourceId, payload });
    setTimeout(async () => {
      await fetchPatientVitalsMedicalEventsQuery.refetch();
    }, 1000);
    setTimeout(async () => {
      await fetchPatientInfoQuery.refetch();
    }, 6500);
  };

  const handleSubmitUpdateAttachmentReport = async (payload: V2PatientAttachmentReportType) => {
    delete payload.createdAt;
    delete payload.updatedAt;
    const reportId = payload.reportId;
    await updatePatientAttachmentsReportsMutation.mutateAsync({ patientSourceId, payload, reportId });
    setTimeout(async () => {
      await fetchPatientVitalsMedicalEventsQuery.refetch();
    }, 1000);
    setTimeout(async () => {
      await fetchPatientInfoQuery.refetch();
    }, 6500);
  };

  const handleDeletePatientReport = async (payload: number) => {
    await deleteReportMutation.mutate({ patientSourceId, payload });

    setTimeout(async () => {
      await fetchPatientVitalsMedicalEventsQuery.refetch();
    }, 1000);
    setTimeout(async () => {
      await fetchPatientInfoQuery.refetch();
    }, 6500);
  };

  const handleChangePatientAttachmentFilters = (filterName: string, value: string) => {
    setPatientAttachmentFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSubmitPatientAttachments = async (fileUpload: any, type: string) => {
    return await updatePatientAttachmentsMutation.mutateAsync({ fileUpload, type });
  };

  const handleSubmitPatientDeviceInfo = async (deviceId: string) => {
    return await updatePatientDeviceInfoMutation.mutateAsync({ patientSourceId, deviceId });
  };

  const handleSubmitDeletePatientDeviceInfo = async (deviceInfo: PatientDevice) => {
    setIsDeviceInfoUpdating(true);
    const deletePayload = {
      patientSourceId,
      deviceId: deviceInfo?.deviceId!,
    };

    return await deletePatientDeviceInfoMutation.mutateAsync(deletePayload);
  };

  const handleSubmitKccq = async (medicalHistory: any) => {
    return await updateKccqMutation.mutateAsync({ medicalHistory });
  };

  const handleSubmitWalkTest = async (medicalHistory: any) => {
    return await updateWalkTestMutation.mutateAsync({ medicalHistory });
  };

  const handleSubmitChangeDefaultRules = async () => {
    if (!_.isEmpty(fechPatientRulesQuery.data)) {
      return;
    }

    await updatePatientRulesChangeDefaultRulesMutation.mutateAsync();
  };

  const handleSubmitNewPatientRule = async (rule: PatientRuleItemType) => {
    if (_.isEmpty(rule)) {
      return;
    }
    createPatientRuleThresholdMutation.mutate({ rule });
  };

  const handleSubmitUpdatePatientRule = async (ruleId: string, rule: PatientRuleItemType) => {
    if (_.isEmpty(rule)) {
      return;
    }

    await updatePatientRuleMutation.mutateAsync({ ruleId, rule });
  };

  const handleSubmitDeletePatientRule = async (ruleId: string) => {
    await deletePatientRuleMutation.mutateAsync({ ruleId });
  };

  const handleSubmitUpdatePatientRuleThreshold = async (rule: PatientRuleItemThresholdType[]) => {
    await updatePatientRuleThresholdMutation.mutateAsync({ rule });
  };

  const handleSubmitPatientMedicalHistory = async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
    await updatePatientMedicalHistoryMutation.mutateAsync({ payload });
  };

  const handleChangeVitalHistoryFitlers = (filters: Partial<VitalHistoryFiltersType>) => {
    setVitalHistoryFilters((prev) => ({ ...prev, ...filters }));
  };

  const handleChangePatientEcgFilters = (filters: Partial<PatientEcgFilterType>) => {
    setPatientEcgFilters((prev) => ({ ...prev, ...filters }));
  };

  const handleChangePatientLogHistoryFilters = (filters: Partial<PatientLogHistoryFilterType>) => {
    setPatientLogsHistoryFilters((prev) => ({ ...prev, ...filters }));
  };

  const handleSubmitNewPatientAppointment = async (payload: AppointmentsType) => {
    await addPatientAppointmentMutation.mutateAsync({ payload });
    setTimeout(() => {
      fetchPatientPrescriptionReviewListQuery.refetch();
    }, 1500);
  };

  const handleSubmitUpdateAppointment = async (payload: AppointmentsType) => {
    if (!payload.appointmentId) {
      return;
    }

    await updatePatientAppointmentMutation.mutate({ payload });
    setTimeout(() => {
      fetchPatientPrescriptionReviewListQuery.refetch();
    }, 1500);
  };

  const handleChangePageOffset = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPatientLogsFilter((prev) => ({ ...prev, pageOffset: Number(newPage) }));
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientLogsFilter((prev) => ({ ...prev, pageOffset: 0 }));
    setPatientLogsFilter((prev) => ({ ...prev, pageLimit: Number(event.target.value) }));
  };

  const handleSubmitDeleteAppointment = async (payload: AppointmentsType) => {
    console.log('>>DC>DS>CD>SC>DS>>DSC>D>S>CS>DC datadata', payload);
    await deletePatientAppointmentMutation.mutate({ appointmentId: payload.appointmentId! });

    setTimeout(() => {
      fetchPatientPrescriptionReviewListQuery.refetch();
    }, 1500);
  };

  const handleSubmitLabReports = async (payload: PatientVitalsReadingType[]) => {
    await updatePatientLabReportMutation.mutate({ payload });
  };

  const handleSubmitMedicalAdherence = async (payload: any[]) => {
    await updatePatientMedicationAdherenceMutation.mutate({ payload });
    setTimeout(() => {
      fetchPatientInfoQuery.refetch();
    }, 500);
  };

  const handleSubmitPatientSymptoms = async (payload: Omit<PatientSymptomsPayloadType, 'patientSourceId' | 'recordId' | 'sourceId'>) => {
    await updatePatientSymptomsMutation.mutate({ payload });
  };

  const handleClickOnAddOrCancelPrescribedMedications = (medications: PatientAlreadyPrescribedMedicationsType, isAdd: boolean) => {
    setPatientAlreadyPrescribedMedications((prev) => {
      return prev.map((item: PatientAlreadyPrescribedMedicationsType) => {
        if (item.medicationName === medications.medicationName) {
          return { ...item, addToNewPrescriptions: isAdd, stopInNewPrescriptions: false };
        }
        return item;
      });
    });
  };

  const handleClickOnStopOrCancelPrescribedMedications = (medications: PatientAlreadyPrescribedMedicationsType, isStop: boolean) => {
    setPatientAlreadyPrescribedMedications((prev) => {
      return prev.map((item: PatientAlreadyPrescribedMedicationsType) => {
        if (item.medicationName === medications.medicationName) {
          return { ...item, stopInNewPrescriptions: isStop, addToNewPrescriptions: false };
        }
        return item;
      });
    });
  };

  const handleUpdatePatientPrescriptions = async (payload: PatientPrescriptionsReportType) => {
    await updatePatientPrescriptionsMutation.mutateAsync({ payload });
    fetchPatientPrescriptionReviewListQuery.refetch();

    setTimeout(() => {
      fetchPatientPrescriptionLogsQuery.refetch();
    }, 100);

    setTimeout(() => {
      fetchPatientVitalsMedicalEventsQuery.refetch();
    }, 500);
  };

  const handleClickSaveGeneralNote = (payload: any) => {
    if (payload.noteId) {
      updatePatientNotesMutation.mutate({ patientSourceId, payload });
      setNote(null);
    } else {
      addPatientNotesMutation.mutate({ patientSourceId, payload });
    }
  };

  const handleClickDeleteNote = async (payload: string) => {
    await deletePatientNotesMutation.mutate({ patientSourceId, payload });
  };

  const handleClickEditPatientNote = (payload: AllSavedNotesType) => {
    setNote(payload);
  };

  const handleClickShowMoreNotes = () => {
    setNoteFilters({ ...noteFilters, noteLimit: noteFilters.noteLimit + 10 });
  };

  const handleChangePrescriptionApprovalFilters = (value: any, variable: string) => {
    setPrescriptionApprovalListFilters((prev) => ({ ...prev, [variable]: value }));
  };

  const toggleEditPatientPrescriptions = (isForceValue = false, value?: boolean) => {
    setIsEditPatientPrescriptions((prev) => {
      if (isForceValue) {
        return value!;
      }
      return !prev;
    });
  };

  const handleSubmitDraftPrescriptionsReview = async (payload: PatientPrescriptionsReportType, reviewId?: string) => {
    await patientPrescriptionReviewDraftMutation.mutateAsync({ payload, reviewId });
    fetchPatientPrescriptionReviewListQuery.refetch();
  };

  const handleSubmitApprovalPrescriptionsReview = async (payload: PatientPrescriptionsReportType, reviewId?: string) => {
    await patientPrescriptionReviewSubmitApprovalMutation.mutateAsync({ payload, reviewId });
    fetchPatientPrescriptionReviewListQuery.refetch();
  };

  const handleSubmitDeletePrescriptionReview = async (prescriptionId: string) => {
    await deletePatientPrescriptionReviewMutation.mutateAsync({ prescriptionId });
    fetchPatientPrescriptionReviewListQuery.refetch();
  };

  const handleSubmitRejectPrescriptionReview = async (prescriptions: PatientPrescriptionReviewListType, notes: string) => {
    await rejectPatientPrescriptionReviewMutation.mutateAsync({ reviewId: prescriptions.reviewId!, notes, prescriptions: prescriptions.prescriptions });
    fetchPatientPrescriptionReviewListQuery.refetch();
  };

  const handleSubmitPatientDeactivation = async (deactivationReason: PatientDeactivationReasonType) => {
    await deactivationOfPatientMutation.mutateAsync({ deactivationReason });
  };

  const handleChangePatientApprovalPrescriptions = (payload: PatientPrescriptionReviewListType | null) => {
    setPatientApprovalPrescriptions(payload);
  };

  React.useEffect(() => {
    if (fetchPatientPrescriptionLogsQuery.data) {
      const patientPrescriptionLogs = fetchPatientPrescriptionLogsQuery.data;

      const prescriptionMedicationsFlatten: PrescriptionsGeneratorMedicationsCommonItemType[] = patientPrescriptionLogs.reduce(
        (acc: PrescriptionsGeneratorMedicationsCommonItemType[], item: PatientPrescriptionsLogsType) => {
          if (item.prescriptions?.prescriptionMedications) {
            acc = acc.concat(item.prescriptions?.prescriptionMedications.map((ele) => ({ ...ele, timestamp: item.prescriptions?.prescriptionDate, recordId: item.recordId })));
          }
          if (!_.isEmpty(item.prescriptions?.notes)) {
            acc = acc.concat(
              item.prescriptions?.notes?.reduce((acc, ele) => {
                if (ele.medicationName) {
                  const brandName = ele.brandName || ele.notes.replace('Stop ', '');

                  acc.push({
                    ...ele,
                    brandName,
                    isStopMedications: true,
                    timestamp: item.prescriptions?.prescriptionDate,
                    recordId: item.recordId,
                  });
                }
                return acc;
              }, [] as any),
            );
          }
          return acc;
        },
        [],
      );

      const prescriptionMedicationsKeys = _.groupBy(prescriptionMedicationsFlatten, (item) => item.medicationName);
      const patientAlreadyPrescribedMedications = Object.keys(prescriptionMedicationsKeys).map((medicationKey) => {
        let temp = prescriptionMedicationsKeys[medicationKey].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        temp = temp.sort((a, b) => b.recordId - a.recordId);
        return temp?.[0];
      });

      setPatientAlreadyPrescribedMedications(JSON.parse(JSON.stringify(patientAlreadyPrescribedMedications)));
    }
  }, [fetchPatientPrescriptionLogsQuery.data]);

  React.useEffect(() => {
    fetchPatientActiveOnVideoCallQuery.refetch();

    setPatientInfo((prev) => {
      const physicianInfo = getOrgUsersQuery.data?.find((e: any) => e.userId === fetchPatientInfoQuery.data?.basicDetails?.cardiologistId);
      prev.basicDetails = {
        ...fetchPatientInfoQuery.data?.basicDetails,
        physicianName: physicianInfo?.user?.name,
      };
      return { ...prev };
    });
  }, [fetchPatientInfoQuery.data, getOrgUsersQuery.data]);

  React.useEffect(() => {
    if (location.state && location.state?.isPatientReview) {
      if (!fetchPatientPrescriptionReviewListQuery.isPending) {
        if (location.state?.reviewId) {
          const data = fetchPatientPrescriptionReviewListQuery.data?.data?.find((item: PatientPrescriptionReviewListType) => item.reviewId === location.state.reviewId);
          if (!_.isEmpty(data)) {
            setPatientApprovalPrescriptions(data!);
          }
        }
        toggleEditPatientPrescriptions(true, true);
        navigate('.', { state: {}, replace: true });
      }
    }
  }, [navigate, location, fetchPatientPrescriptionReviewListQuery.data?.data]);

  React.useEffect(() => {
    setTimeout(() => {
      fetchPatientActiveOnVideoCallQuery.refetch();
    }, 1000);
  }, [showVideoCall, onGoingVideoCall]);

  const providerValue: PatientProfileContextProps = {
    isAPILoading: getOrgUsersQuery.isPending || fetchPatientInfoQuery.isPending || fetchPatientPrescriptionLogsQuery.isPending,
    fetchPatientInfoQuery,
    patientInfo,
    setSelectedPdfUrl,
    selectedPdfUrl,
    patientVideoCallStatus,
    patientPrescriptionLogs: fetchPatientPrescriptionLogsQuery.data || [],
    nextReviewSuggestion: {} as any,
    patientAlreadyPrescribedMedications,

    handleClickOnAddOrCancelPrescribedMedications,
    handleClickOnStopOrCancelPrescribedMedications,

    isPatientPrescriptionUpdating: updatePatientPrescriptionsMutation.isPending,
    handleUpdatePatientPrescriptions,

    isEditPatientPrescriptions,
    toggleEditPatientPrescriptions,

    isPatientPrescriptionReviewListLoading: fetchPatientPrescriptionReviewListQuery.isPending,
    patientPrescriptionReviewList: fetchPatientPrescriptionReviewListQuery.data,
    prescriptionApprovalListFilters,
    handleChangePrescriptionApprovalFilters,
    unmappedDevices,
    isUpdatingDraftPatientPrescriptionReview: patientPrescriptionReviewDraftMutation.isPending,
    handleSubmitDraftPrescriptionsReview,

    isUpdatingSubmitApprovalPrescriptionReview: patientPrescriptionReviewSubmitApprovalMutation.isPending,
    handleSubmitApprovalPrescriptionsReview,

    isDeletePatientPrescriptionReview: deletePatientPrescriptionReviewMutation.isPending,
    handleSubmitDeletePrescriptionReview,

    isRejectPatientPrescriptionReview: rejectPatientPrescriptionReviewMutation.isPending,
    handleSubmitRejectPrescriptionReview,

    isLoadingPatientAttachments: fetchPatientAttachmentsQuery.isPending,
    isPatientAttachmentUploading: updatePatientAttachmentsMutation.isPending,

    patientAttachments: fetchPatientAttachmentsQuery.data,
    patientAttachmentFilters,
    handleSubmitKccq,
    handleSubmitWalkTest,
    handleSubmitPatientAttachments,
    handleChangePatientAttachmentFilters,

    isUpdatingPatientRule:
      updatePatientRuleMutation.isPending || deletePatientRuleMutation.isPending || updatePatientRulesChangeDefaultRulesMutation.isPending || createPatientRuleThresholdMutation.isPending,
    patientRules: fechPatientRulesQuery.data,
    handleSubmitNewPatientRule,
    handleSubmitUpdatePatientRule,
    handleSubmitDeletePatientRule,
    handleSubmitChangeDefaultRules,
    isUpdatingPatientRuleThreshold: updatePatientRuleThresholdMutation.isPending,
    patientRuleThreshold: fechPatientRulesThresholdQuery.data,
    handleSubmitUpdatePatientRuleThreshold,

    isPatientMedicalHistoryUpdatingApiCalling: updatePatientMedicalHistoryMutation.isPending,
    handleSubmitPatientMedicalHistory,

    patientMedicationTask: fetchPatientMedicationTasksQuery.data,
    isPatientVitalsHistoryLoading: fetchPatientVitalsByRangeGraphQuery.isPending,
    handleSubmitPatientDeviceInfo,
    vitalHistoryFilters,
    patientVitalsHistory: fetchPatientVitalsByRangeGraphQuery.data || [],
    patientVitalsHistoryAppleWatch: fetchPatientVitalsAppleWatchByRangeGraphQuery.data || [],
    patientVitalsHistoryMedicalEvent,

    handleChangeVitalHistoryFitlers,
    patientLogsFilter,
    isPatientEcgLoading: fetchPatientEcgQuery.isPending,

    patientEcgFilter,
    patientEcgs: fetchPatientEcgQuery.data || { count: 0, data: [] },

    patientPreferenceVitalsGraphView: fetchPatientGraphPreference?.data?.preferences || {
      dbp: true,
      sbp: true,
      spo2: true,
      steps: true,
      weight: true,
      hrApple: true,
      hrOmron: true,
      medicalEvents: true,

      medianLine: true,
    },
    isPatientPreferenceVitalsGraphViewLoading: fetchPatientGraphPreference.isPending,
    handleSubmitPatientGraphPreference,

    handleChangePatientEcgFilters,
    isDeviceInfoUpdating,
    isPatientLogsLoading: fetchHistoryLogsQuery.isPending,
    isPatinetProfileLogsLoading: getPatientLogsQuery.isPending,

    patientLogsHistoryFilter,
    patientLogsHistory: fetchHistoryLogsQuery.data || { logsData: [], count: 0 },
    patientLogsData: getPatientLogsQuery.data || { logsData: [], count: 0 },
    handleChangePatientLogHistoryFilters,

    isAppointmentUpdating: addPatientAppointmentMutation.isPending || updatePatientAppointmentMutation.isPending || deletePatientAppointmentMutation.isPending,

    appointmentList,

    handleSubmitNewPatientAppointment,
    handleSubmitUpdateAppointment,
    handleSubmitDeleteAppointment,
    handleChangePageOffset,
    handleChangeLimit,
    handleClickSaveGeneralNote,
    allSavedNotes: formattedSavedNotes || [],
    profileSavedNotes: [],
    note,
    handleClickDeleteNote,
    handleClickEditPatientNote,
    handleClickShowMoreNotes,
    noteCount: fetchPatientNotesQuery.data?.count || 0,
    noteFilters,
    handleChangeNoteFilters,
    handleClickResetNoteFilters,
    notesIsLoading: fetchPatientNotesQuery.isRefetching || fetchPatientNotesQuery.isLoading || updatePatientNotesMutation.isPending || addPatientNotesMutation.isPending,
    allSavedNotesIsLoading: fetchPatientNotesQuery.isLoading,
    noteUsersOptions: getOrgUsersQuery.isFetched ? getOrgUsersQuery.data?.map((e: any) => ({ label: e.user?.name, value: e.user?.userId })) : [],
    handleClickToggleMyNotesOnly,
    isLabReportUpdatingApiCalling: updatePatientLabReportMutation.isPending,
    handleSubmitLabReports,

    isMedicalAdherenceUpdatingApiCalling: updatePatientMedicationAdherenceMutation.isPending,
    handleSubmitMedicalAdherence,

    isPatientSymptomsUpdatingApiCalling: updatePatientSymptomsMutation.isPending,
    handleSubmitPatientSymptoms,

    isPatientDeactivationUpdating: deactivationOfPatientMutation.isPending,
    handleSubmitPatientDeactivation,
    mappedDevices,
    patientApprovalPrescriptions,
    handleChangePatientApprovalPrescriptions,
    handleDeletePatientReport,
    isPatientAttachmentsUpdating: addPatientAttachmentsReportsMutation.isPending,
    handleSubmitPatientAttachment: handleSubmitPatientAttachmentReport,
    handleSubmitUpdateAttachment: handleSubmitUpdateAttachmentReport,

    isLoadingPatientAttachmentsReports: fetchPatientAttachmentsReports.isPending,
    refetchPatientDetails: fetchPatientAttachmentsReports,
    handleSubmitDeletePatientDeviceInfo,
    patientAttachmentsReportsData: fetchPatientAttachmentsReports.data || [],
  };

  return (
    <PatientProfileContext.Provider value={providerValue}>
      <PatientProfile />
    </PatientProfileContext.Provider>
  );
};

export default PatientListProvider;
