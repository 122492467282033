/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { useQuery } from '@tanstack/react-query';

import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import PDFViewer from '../common/PDFViewer';

import { PatientPrescriptionsReportType, PrescriptionNotesType, PrescriptionsGeneratorMedicationsCommonItemType } from '../../types/PrescriptionsGenerator.types';

import { fetchPatientAttachmentsPresignedUrl } from '../../service/patient-attachments';

interface MedicationChangeTimelineProps {
  patientSourceId: string;
  prescriptions: PatientPrescriptionsReportType;

  organiationUsers: { label: string; value: string }[];
}

const MedicationChangeTimeline: React.FunctionComponent<MedicationChangeTimelineProps> = (props) => {
  const [isOpenPrescriptionModal, setIsOpenPrescriptionModal] = React.useState<boolean>(false);

  const patientPrescriptionsReportURLQuery = useQuery({
    queryKey: ['patientAttachementUrl', props.patientSourceId, props.prescriptions?.pdfAttachment?.attachmentId],
    queryFn: () => fetchPatientAttachmentsPresignedUrl(props.patientSourceId, props.prescriptions?.pdfAttachment?.attachmentId!),
  });

  const togglePrescriptionModal = () => {
    setIsOpenPrescriptionModal((prev) => !prev);
  };

  const prescriptions = props.prescriptions || {};

  const showObservationAssentSections = !_.isEmpty(prescriptions.complaints) || !_.isEmpty(prescriptions.diagnosis) || !_.isEmpty(prescriptions.examinations);
  let prescribedBy = '';

  if (prescriptions?.prescribedBy?.userId && props.organiationUsers) {
    const user = props.organiationUsers.find((item) => item.value === prescriptions?.prescribedBy?.userId);
    prescribedBy = user?.label!;
  }

  return (
    <>
      {isOpenPrescriptionModal && <PDFViewer isOpen={isOpenPrescriptionModal} handleClose={togglePrescriptionModal} pdfUrl={patientPrescriptionsReportURLQuery.data} />}

      {showObservationAssentSections && (
        <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontSemiBold12' color='#3E4685'>
              Observation and Assessments
            </Typography>
          </ListItem>

          {!_.isEmpty(prescriptions.complaints) && (
            <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
              <Typography variant='fontSemiBold12' color='#3E4685' sx={{ mr: 1.5 }}>
                Complatints
              </Typography>

              {prescriptions.complaints?.map((item, index) => (
                <React.Fragment key={index}>
                  <Typography variant='fontSemiBold12' color='#3E4685'>
                    {item.complaints}
                  </Typography>

                  <Typography variant='fontSemiBold12' color='#3E4685' sx={{ pl: 1 }}>
                    {item.observedFrom}
                  </Typography>
                </React.Fragment>
              ))}
            </ListItem>
          )}

          {!_.isEmpty(prescriptions.examinations) && (
            <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
              <Typography variant='fontSemiBold12' color='#3E4685' sx={{ mr: 1.5 }}>
                Examination
              </Typography>

              {prescriptions.examinations?.map((item, index) => (
                <React.Fragment key={index}>
                  <Typography variant='fontSemiBold12' color='#3E4685'>
                    {item.examinations}
                  </Typography>
                </React.Fragment>
              ))}
            </ListItem>
          )}

          {!_.isEmpty(prescriptions.diagnosis) && (
            <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
              <Typography variant='fontSemiBold12' color='#3E4685' sx={{ mr: 1.5 }}>
                Diagnosis
              </Typography>

              {prescriptions.diagnosis?.map((item, index) => (
                <React.Fragment key={index}>
                  <Typography variant='fontSemiBold12' color='#3E4685'>
                    {item.diagnosis}
                  </Typography>
                </React.Fragment>
              ))}
            </ListItem>
          )}
        </List>
      )}

      <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
        <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
          <Typography variant='fontSemiBold12' color='#3E4685'>
            Medications
          </Typography>
        </ListItem>
        {prescriptions?.prescriptionMedications?.map((item: PrescriptionsGeneratorMedicationsCommonItemType, index: number) => {
          return (
            <ListItem sx={{ borderBottom: prescriptions?.prescriptionMedications.length - 1 === index ? 'none' : '1px solid #D5D5D6' }}>
              <Grid container justifyContent='space-between'>
                <Grid item xs={5}>
                  <Typography component='div' variant='fontSemiBold14' color='#3E4685' sx={{ wordBreak: 'break-word' }}>
                    {item.brandName}
                  </Typography>
                  <Typography component='div' variant='fontReg12' color='#3E4685' sx={{ wordBreak: 'break-word' }}>
                    {item.medicationNameDisplayName || item.medicationName}&nbsp; {item.dosage}&nbsp; {item.dosageUnit}
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography variant='fontSemiBold14' color='#3E4685' textAlign='center'>
                    {item.frequencyRegime}
                  </Typography>
                </Grid>

                <Grid item xs={1.8}>
                  <Typography variant='fontSemiBold12' color='#3E4685'></Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography variant='fontSemiBold12' color='#3E4685'>
                    {item.duration} <small>{item.durationUnits}</small>
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>

      {prescriptions && prescriptions?.notes && prescriptions?.notes?.length > 0 && (
        <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontSemiBold12' color='#3E4685'>
              Notes
            </Typography>
          </ListItem>
          {prescriptions?.notes?.map((item: PrescriptionNotesType, index: number) => {
            return (
              <ListItem sx={{ borderBottom: prescriptions?.prescriptionMedications.length - 1 === index ? 'none' : '1px solid #D5D5D6' }}>
                <Typography component='div' variant='fontReg14' color='#3E4685'>
                  {item.notes}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      )}

      {prescriptions?.pdfAttachment?.attachmentId && (
        <Button variant='text' sx={{ my: 1 }} onClick={togglePrescriptionModal}>
          <Typography component='div' variant='fontSemiBold14' color='#3E4685' sx={{ textDecoration: 'underline' }}>
            Medication Prescriptions PDF
          </Typography>
        </Button>
      )}

      {prescribedBy && (
        <Typography component='div' variant='fontSemiBold14' color='#3E4685' sx={{ mt: 1.5 }}>
          <small>Prescribed By:</small> {prescribedBy}
        </Typography>
      )}
    </>
  );
};

export default MedicationChangeTimeline;
