/** @format */

import _ from 'underscore';
import * as React from 'react';
// import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CustomSlider from './CustomSlider';
import CustomTable from '../common/custom-table/CustomTable';

import { getVitalDisaplyLabel } from '../../utils/patient-rule';

import { CustomTableColumnType } from '../../types/CustomTable.types';
import { PatientRuleItemThresholdType } from '../../types/PatientRule.types';

import { PatientRuleConditionVitalsOptions } from '../../constants/PatientRules';
import { InfoIcon } from '../../assets/InfoIcon';
import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material';

interface PatientRuleThresholdProps {
  hideSaveThreshold?: boolean;

  isForceEdit?: boolean;

  defaultEdit?: boolean;

  rules: PatientRuleItemThresholdType[];
  isUpdatingPatientRuleThreshold: boolean;

  handleChangeRulesThreshold?: (rule: PatientRuleItemThresholdType[]) => void;

  handleSubmitUpdatePatientRuleThreshold: (rule: PatientRuleItemThresholdType[]) => void;
}

const PatientRuleThreshold: React.FunctionComponent<PatientRuleThresholdProps> = (props) => {
  // const appTheme = useTheme();

  const prevIsUpdatingPatientRuleRef = React.useRef(props.isUpdatingPatientRuleThreshold);

  // const [
  //   // expandedRule,
  //   // setExpandedRule,
  // ] = React.useState<boolean>(true);

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [ruleThreshold, setRuleThreshold] = React.useState<PatientRuleItemThresholdType[]>([]);

  React.useEffect(() => {
    if (props.rules) {
      setRuleThreshold(JSON.parse(JSON.stringify(props.rules)));
    }
  }, [props.rules]);

  // const toggleExpandRule = () => {
  //   setExpandedRule((prev) => !prev);
  // };

  const handleChangeConditionInputs = (value: any, variable: string, index: number) => {
    setRuleThreshold((prev) => {
      prev = prev.map((item: any, itemIndex) => {
        if (index === itemIndex) {
          item[variable] = value;
        }
        return item;
      });

      if (props.handleChangeRulesThreshold) {
        props.handleChangeRulesThreshold(prev);
      }

      return prev;
    });
  };

  const handleChangeNotificationPreferenceInput = (value: any, variable: string, index: number) => {
    setRuleThreshold((prev) => {
      prev = prev.map((item: any, itemIndex) => {
        if (index === itemIndex) {
          if (!item.notificationPreferences) {
            item.notificationPreferences = {};
          }
          item.notificationPreferences[variable] = value;
        }
        return item;
      });

      if (props.handleChangeRulesThreshold) {
        props.handleChangeRulesThreshold(prev);
      }

      return prev;
    });
  };

  const handleSubmitSaveRule = () => {
    props.handleSubmitUpdatePatientRuleThreshold(ruleThreshold);
  };

  const toggleEditRule = () => {
    setIsEdit((prev) => {
      if (prev) {
        setRuleThreshold(JSON.parse(JSON.stringify(props.rules)));
      }
      if (!prev) {
        // setExpandedRule(true);
      }
      return !prev;
    });
  };

  React.useEffect(() => {
    if (isEdit && prevIsUpdatingPatientRuleRef.current && !props.isUpdatingPatientRuleThreshold) {
      setIsEdit(false);
    }
    prevIsUpdatingPatientRuleRef.current = props.isUpdatingPatientRuleThreshold;
  }, [props.isUpdatingPatientRuleThreshold, isEdit, prevIsUpdatingPatientRuleRef]);

  React.useEffect(() => {
    if (props.defaultEdit) {
      setIsEdit(true);
      // setExpandedRule(true);
    }
  }, []);

  React.useEffect(() => {
    if (props.isForceEdit) {
      setIsEdit(true);
      // setExpandedRule(true);
    } else {
      setIsEdit(false);
    }
  }, [props.isForceEdit]);

  const column: CustomTableColumnType[] = [
    {
      id: 'select-vital',
      label: <Typography variant='fontSemiBold14'>Medical Parameters</Typography>,
      cellAlignment: 'left',
      minWidth: 140,
      maxWidth: 140,
      cellFormatter: (rule: PatientRuleItemThresholdType, index: number) => {
        const options = PatientRuleConditionVitalsOptions.find((item) => item.value === rule.vitalsType);
        return (
          <Typography variant='fontReg14'>
            {getVitalDisaplyLabel(rule.vitalsType)}
            <br />({options?.unit})
          </Typography>
        );
      },
    },
    {
      id: 'critical-low',
      minWidth: 200,
      maxWidth: 200,
      // label: 'Critical Threshold',
      label: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ marginRight: '5px' }} variant='fontSemiBold14'>
            Critical Thresholds
          </Typography>

          <InfoIcon title='Any reading outside Critical Threshold limits will trigger a Review action.' />
        </Box>
      ),
      cellAlignment: 'left',
      cellFormatter: (rule: PatientRuleItemThresholdType, index: number) => {
        const options = PatientRuleConditionVitalsOptions.find((item) => item.value === rule.vitalsType);

        const sliderProps = {
          tooltipEditable: isEdit,
          disabled: !isEdit,
          minRange: options?.minRange!,
          maxRange: options?.maxRange!,
          defaultRange: options?.defaultRange!,
          value: [rule.criticalLow, rule.criticalHigh],
          handleChangeValue: console.info,
          step: options?.step,
        };
        if (isEdit) {
          sliderProps.handleChangeValue = (value: any) => {
            handleChangeConditionInputs(value[0], 'criticalLow', index);
            handleChangeConditionInputs(value[1], 'criticalHigh', index);
          };
        }

        return (
          <Box sx={{ paddingRight: '20px' }}>
            <CustomSlider {...sliderProps} />
          </Box>
        );
      },
    },
    {
      id: 'warning-High',
      minWidth: 200,
      maxWidth: 200,
      label: (
        <Typography sx={{ marginRight: '5px' }} variant='fontSemiBold14'>
          Warning Thresholds
        </Typography>
      ),
      cellAlignment: 'left',
      cellFormatter: (rule: PatientRuleItemThresholdType, index: number) => {
        const options = PatientRuleConditionVitalsOptions.find((item) => item.value === rule.vitalsType);
        const sliderProps = {
          tooltipEditable: isEdit,
          disabled: !isEdit,
          minRange: options?.minRange!,
          maxRange: options?.maxRange!,
          defaultRange: options?.defaultRange!,
          value: [rule.warningLow, rule.warningHigh],
          handleChangeValue: console.info,
          step: options?.step,
        };

        if (isEdit) {
          sliderProps.handleChangeValue = (value: any) => {
            handleChangeConditionInputs(value[0], 'warningLow', index);
            handleChangeConditionInputs(value[1], 'warningHigh', index);
          };
        }
        return (
          <Box sx={{ paddingRight: '20px' }}>
            <CustomSlider {...sliderProps} />
          </Box>
        );
      },
    },
    {
      id: 'notifications',
      minWidth: 90,
      maxWidth: 90,
      label: <Typography variant='fontSemiBold14'>Notifications</Typography>,
      cellAlignment: 'left',
      cellFormatter: (rule: PatientRuleItemThresholdType, index: number) => {
        return (
          <FormGroup sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center' }}>
            <FormControlLabel
              sx={{ marginLeft: 0 }}
              control={<Switch disabled={!isEdit} checked={rule.notificationPreferences?.sms} onChange={(event, checked: boolean) => handleChangeNotificationPreferenceInput(checked, 'sms', index)} />}
              label={<Typography variant='fontReg14'>SMS&nbsp;&nbsp;&nbsp;</Typography>}
              labelPlacement='start'
            />
            <FormControlLabel
              sx={{ marginLeft: 0 }}
              control={
                <Switch disabled={!isEdit} checked={rule.notificationPreferences?.email} onChange={(event, checked: boolean) => handleChangeNotificationPreferenceInput(checked, 'email', index)} />
              }
              label={<Typography variant='fontReg14'>EMAIL</Typography>}
              labelPlacement='start'
            />
          </FormGroup>
        );
      },
    },
  ];

  const disableConditionButton = !ruleThreshold.every((item) => !_.isEmpty(item.vitalsType));

  return (
    <Box className='alerts-threshold'>
      <Grid item xs={12} container gap={2} alignItems='center' justifyContent='space-between'>
        {/* <Typography variant='fontBold20' pl={2} color={appTheme.palette.customColor.primary} textTransform='capitalize'>
          Alerts Thresholds
        </Typography> */}

        <Grid item display='flex' justifyContent='flex-end' flex={1}>
          {/* <Box sx={{ backgroundColor: 'red' }}>helo world</Box> */}

          {!isEdit && !props.hideSaveThreshold && (
            <IconButton disabled={props.isUpdatingPatientRuleThreshold} onClick={toggleEditRule}>
              <EditIcon />
            </IconButton>
          )}

          {isEdit && !props.hideSaveThreshold && (
            <>
              <Tooltip title='Cancel'>
                <IconButton disabled={props.isUpdatingPatientRuleThreshold} aria-label='Cancel' onClick={toggleEditRule}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Save'>
                <IconButton disabled={props.isUpdatingPatientRuleThreshold || disableConditionButton} aria-label='Save' onClick={handleSubmitSaveRule}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </>
          )}

          {/* <IconButton onClick={toggleExpandRule} sx={{ transform: !expandedRule ? 'rotate(0deg)' : 'rotate(180deg)' }}>
            <ExpandMoreIcon />
          </IconButton> */}
        </Grid>
      </Grid>
      <CustomTable data={ruleThreshold} columns={column} />
      {/* <Collapse in={expandedRule} timeout='auto' unmountOnExit>
      </Collapse> */}
    </Box>
  );
};

PatientRuleThreshold.defaultProps = {
  hideSaveThreshold: false,
  defaultEdit: false,
};

export default PatientRuleThreshold;
