/** @format */

import React from 'react';
import { Box, Chip, FormControl, IconButton, Input, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, Select, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// import RemoveIcon from '@mui/icons-material/Remove';
import RestoreIcon from '@mui/icons-material/Restore';
// import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import { DropdownOptionType } from '../../../types/CustomForm.types';
export interface MultiInputInterface {
  type: 'select' | 'input';
  label: string | null;
  value: string;
  options?: DropdownOptionType[];
  onChange: (payload: string) => void;
  inputType?: string;
}

export type ObservationInterface = {
  mainText: string;
  subText?: string;
  id: string;
};

interface CustomObservationsInterface {
  observationTitle: string;
  observationActionTitle: string;
  options: DropdownOptionType[];
  handleChangeObservations?: (payload: ObservationInterface[]) => void;
  inputs: MultiInputInterface[];
  bufferText?: string;
  existingObservations: ObservationInterface[] | null;
  inputType?: string;

  onClickEnterSaveTooltipText?: string;
}

export const CustomObservations: React.FC<CustomObservationsInterface> = ({
  observationTitle,
  observationActionTitle,
  options,
  handleChangeObservations,
  inputs,
  bufferText,
  existingObservations,
  inputType,

  onClickEnterSaveTooltipText,
}) => {
  const [observation, setObservation] = React.useState<string>('');
  const [observationActive, setObservationActive] = React.useState<boolean>(false);
  const [observations, setObservations] = React.useState<Array<ObservationInterface>>([]);
  const [currentId, setCurrentId] = React.useState<string | null>(null);
  const inputRef = React.useRef<HTMLDivElement | null>(null);
  const secondaryInputRef = React.useRef<HTMLInputElement | null>(null);

  const [textWidth, setTextWidth] = React.useState(0);
  React.useEffect(() => {
    const measureTextWidth = () => {
      if (inputRef.current) {
        const context = document.createElement('canvas').getContext('2d');
        if (context) {
          // Set the font properties to match the input field
          context.font = window.getComputedStyle(inputRef.current).font;
          setTextWidth(context.measureText((inputRef.current as any).value).width);
        }
      }
    };

    measureTextWidth();
  }, [observation]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (existingObservations) {
      setObservations(existingObservations);
      // handleChangeObservations!(existingObservations);
    }
  }, [existingObservations]);

  React.useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      setObservationActive(false);
    }
  }, [document.activeElement]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setObservationActive(true);
    // inputRef.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setObservationActive(false);
  };

  const handleClickDeleteObservation = (payload: string) => {
    setObservations((prev) => {
      prev = prev.filter((e) => e.id !== payload);
      if (handleChangeObservations) {
        handleChangeObservations(prev);
      }
      return prev;
    });
  };

  const handleClickEditObservation = (payload: string, id: string) => {
    setObservation(payload);
    inputRef.current?.focus();
    setCurrentId(id);
    // setObservations(observations.filter((e) => e.id !== id));
  };

  const calculateInputValues = (): string => {
    if (inputs?.[0]?.value?.length === 0) {
      return '';
    }

    return inputs.reduce((acc, curr) => {
      if (curr.value.length !== 0) {
        return `${acc + ' ' + curr.value}`.trim();
      } else {
        return '';
      }
    }, '');
  };

  const handleClickObservationValue = (payload: string) => {
    setObservation(payload);

    handleClose();
  };

  const saveObservation = (payload: string) => {
    if (observation.length === 0) {
      return;
    }

    if (currentId && observations.find((e) => e.id === currentId)) {
      setObservations((prev) => {
        prev = prev.map((e) => {
          if (e.id === currentId) {
            return {
              ...e,
              mainText: observation,
              subText: inputs.length !== 0 && inputs[0].value.length !== 0 && payload ? `${bufferText} ${inputs[0].value} ${payload}` : undefined,
            };
          } else {
            return e;
          }
        });

        if (handleChangeObservations) {
          handleChangeObservations(prev);
        }
        return prev;
      });
    } else {
      setObservations((prev) => {
        prev = [
          ...prev,
          {
            id: Math.random().toString(),
            mainText: observation,
            subText: inputs.length !== 0 && inputs[0].value.length !== 0 && payload ? `${bufferText} ${inputs[0].value} ${payload}` : undefined,
          },
        ];
        if (handleChangeObservations) {
          handleChangeObservations(prev);
        }
        return prev;
      });
    }
    setCurrentId(null);

    setObservation('');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].onChange('');
    }
    inputRef.current?.focus();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (observation.length === 0) {
      return;
    }

    const inputValues = calculateInputValues();

    if (event.key === 'Enter') {
      setAnchorEl(null);
      if (currentId && observations.find((e) => e.id === currentId)) {
        const newObservations = observations.map((e) => {
          if (e.id === currentId) {
            return {
              ...e,
              mainText: observation,
              subText: inputValues.length !== 0 ? (bufferText ? `${bufferText} ${inputValues}` : inputValues) : undefined,
            };
          } else {
            return e;
          }
        });

        if (handleChangeObservations) {
          handleChangeObservations(newObservations);
        }
        setObservations(newObservations);
      } else {
        setObservations((prev) => {
          prev = [
            ...prev,
            {
              id: Math.random().toString(),
              mainText: observation,
              subText: inputValues.length !== 0 ? (bufferText ? `${bufferText} ${inputValues}` : inputValues) : undefined,
            },
          ];

          if (handleChangeObservations) {
            handleChangeObservations(prev);
          }
          return prev;
        });
      }
      setCurrentId(null);

      setObservation('');
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].onChange('');
      }
      // inputRef.current?.focus();
      // setAnchorEl(null);
    }

    if (event.key === 'Tab') {
      setAnchorEl(null);
    }
  };

  const _renderExtendedObservations = () => {
    if (inputs) {
      return (
        <Box sx={{ width: 'fit-content', display: 'flex' }}>
          {inputs.map((e: MultiInputInterface) => {
            if (e.type === 'input') {
              return (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {e.label && (
                    <Typography variant='fontSemiBold14' color='primary' sx={{ marginLeft: '2px' }}>
                      {e.label}
                    </Typography>
                  )}

                  <Input
                    disableUnderline={true}
                    value={e.value}
                    // tabIndex={1}
                    inputRef={secondaryInputRef}
                    onChange={({ currentTarget }) => e.onChange(currentTarget.value)}
                    sx={{
                      boxShadow: 'none',
                      border: '1px solid #9BB4CC',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      height: '32px',
                      padding: '0 10px',
                      '.MuiInput-input': {
                        padding: 0,
                      },
                      marginTop: '10px',
                      // maxWidth: '80px',
                    }}
                    onKeyDown={handleKeyPress}
                    type={e.inputType}
                  />
                </Box>
              );
            } else {
              return (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                  {e.label && (
                    <Typography variant='fontSemiBold14' color='primary' sx={{ marginLeft: '2px' }}>
                      {e.label}
                    </Typography>
                  )}

                  <FormControl
                    size='small'
                    sx={{
                      border: '1px solid #9BB4CC',
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: observations.length !== 0 ? 0 : '5px',
                      borderLeft: 0,
                      minWidth: '120px',
                      marginTop: '10px',
                    }}>
                    <Select
                      variant='standard'
                      disableUnderline={true}
                      value={e.value}
                      sx={{
                        borderRadius: '5px',
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                          border: 0,
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 0,
                        },
                        '.MuiSelect-outlined': { padding: 0 },
                        '.MuiSelect-standard': { padding: '4px 0 3px 10px' },
                        '.MuiSelect-nativeInput': { padding: 0 },
                        border: 'none',
                        '&:hover': { border: 'none' },
                        '&:active': { border: 'none', boxShadow: 'none' },
                        // '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        '.MuiList-root': { border: '1px solid #9BB4CC', borderTop: 'none', padding: 0 },
                      }}
                      onChange={(event: any) => {
                        e.onChange(event.target.value);
                        saveObservation(event.target.value);
                      }}>
                      {e.options?.map((e) => {
                        return (
                          <MenuItem value={e.value} key={e.value} sx={{ borderBottom: '1px solid #9BB4CC', '&:last-of-type': { borderBottom: 'none' } }}>
                            {e.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              );
            }
          })}
        </Box>
      );
    } else {
      return null;
    }
  };

  const _renderMenu = () => {
    if (options.length === 0) {
      return null;
    }

    return (
      <Menu
        id='basic-menu'
        disableAutoFocus={true}
        disableRestoreFocus={false}
        anchorEl={anchorEl}
        onFocus={() => inputRef.current?.focus()}
        open={open}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            secondaryInputRef.current?.focus();
          }
        }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={0}
        sx={{ boxShadow: 'none', '.MuiList-root': { border: '1px solid #9BB4CC', borderTop: 'none', padding: 0, width: inputRef.current?.clientWidth } }}>
        {options.map((e) => {
          return (
            <MenuItem key={e.value} sx={{ borderBottom: '1px solid #9BB4CC', '&:last-of-type': { borderBottom: 'none' } }} onClick={() => handleClickObservationValue(e.label)}>
              <ListItemIcon>
                <RestoreIcon color='primary' />
              </ListItemIcon>

              <ListItemText>{e.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', marginTop: '10px' }}>
        <FormControl variant='outlined' sx={{ flex: 1 }}>
          <Typography variant='fontSemiBold14' color='primary' sx={{ marginLeft: '2px' }}>
            {observationTitle}
          </Typography>
          <Input
            type={inputType}
            inputRef={inputRef}
            // onBlur={() => {
            //   if (anchorEl) {
            //     inputRef.current?.focus();
            //   }
            // }}
            // tabIndex={0}
            disableUnderline={true}
            startAdornment={
              !observationActive && observation.length === 0 ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <IconButton
                    sx={{ paddingLeft: 0 }}
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <AddIcon />
                  </IconButton>
                </Box>
              ) : null
            }
            endAdornment={
              observation.trim().length > 0 ? (
                <InputAdornment
                  position='end'
                  sx={{
                    position: `${(textWidth < inputRef?.current?.offsetWidth! - 200 && 'absolute') || null}`,
                    left: `${textWidth + 30}px`,
                    color: 'gray',
                  }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='fontReg12' sx={{ textWrap: 'nowrap' }} color='#696969'>
                      Press
                      <IconButton id='enter-icon-button' disabled={true} sx={{ ml: 1, mr: 1, border: '1px solid darkgray', borderRadius: 0, pr: 1, pl: 1, pt: 0, pb: 0 }}>
                        <Typography variant='fontSemiBold12' color='black'>
                          Enter
                        </Typography>
                      </IconButton>
                      to capture
                    </Typography>
                  </Box>
                </InputAdornment>
              ) : null
            }
            onClick={(e) => handleClick(e)}
            value={observation}
            onKeyDown={(e) => handleKeyPress(e)}
            onChange={({ currentTarget }) => setObservation(currentTarget.value)}
            placeholder={!observationActive ? observationActionTitle : ''}
            sx={{
              boxShadow: 'none',
              border: '1px solid #9BB4CC',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: inputs.length !== 0 ? 0 : '5px',
              borderBottomLeftRadius: observations.length !== 0 ? 0 : '5px',
              borderBottomRightRadius: observations.length !== 0 || inputs.length !== 0 ? 0 : '5px',
              marginTop: '10px',
              borderRight: inputs.length !== 0 ? 'none' : '1px solid #9BB4CC',
              height: '32px',
              padding: '0 10px',
              '.MuiInput-input': {
                padding: 0,
                '&::placeholder': {
                  color: '#33425B',
                  opacity: 1,
                },
              },
            }}
          />
        </FormControl>

        {_renderExtendedObservations()}

        {/* {observationActive ? (
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
            <IconButton aria-label='delete' sx={{ padding: '5px', marginLeft: '5px' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : null} */}
      </Box>

      {_renderMenu()}

      {observations.length !== 0 && (
        <Box
          sx={{
            borderBottom: '1px solid #9BB4CC',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            paddingBottom: '8px',
            borderRight: '1px solid #9BB4CC',
            borderLeft: '1px solid #9BB4CC',
            padding: '2px',
          }}>
          {observations.map((e) => {
            return (
              <Chip
                label={
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography variant='fontReg14' color='primary'>
                      {e.mainText}
                    </Typography>
                    {e.subText && (
                      <Typography variant='fontReg12' color='primary' sx={{ marginLeft: '5px' }}>
                        {e.subText}
                      </Typography>
                    )}
                  </Box>
                }
                variant='outlined'
                sx={{ backgroundColor: '#EFF1FF', margin: '5px' }}
                deleteIcon={<CloseIcon />}
                onClick={() => handleClickEditObservation(e.mainText, e.id)}
                onDelete={() => handleClickDeleteObservation(e.id)}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};
